/* other form Css is in Login.css */
.confirm-otp-modal {
  background-color: var(--primary-color);
  z-index: 5;
  max-width: 650px;
  width: 97%;
  padding: 0.9rem;

  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3rem;
}

.subscription-modal {
  width: 92%;
  background: var(--primary-color);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 1150px;
  margin: 0 auto;
  padding: 1.2rem 1rem;
  border-radius: 8px;
  height: 93vh;
  overflow-y: scroll;
  scrollbar-width: none;
}

.subscription-modal button {
  height: 48px;
  padding: 8px;
  gap: 10px;
  border-radius: 8px;
  color: #fff;
  background: var(--primary-btn-color);
  align-self: flex-end;
}

.subscription-actons {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  gap: 2rem;
}

.subcription-actons__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.subcription-actons__text h4 {
  font-size: 1.3rem;
}

.subcription-actons__text p {
  font-size: 1rem;
}

.subscription-actons button {
  align-self: center;
}

/* @media screen and ((max-width: 697px)) {
  .subscription-modal {
    overflow-x: hidden;
  }

  .subscription-modal .subscription-table {
    min-width: 90%;
    overflow-x: scroll;
  }
} */
