.multiplayer-result-con {
  background-color: var(--primary-color);
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 4rem;

  border-radius: 0.5rem;
  scrollbar-width: none;
}

.multiplayer-result-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}

.multiplayer-result-main__top {
  background-color: var(--light-blue);
  width: 100%;
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.multiplayer-result-main__top-header {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;
}

.multiplayer-result-main__top-header div {
  cursor: pointer;
}

.multiplayer-result-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
  position: relative;
  padding-top: 2rem;
}

.multiplayer-result-profile__crown {
  position: absolute;
  top: -1rem;
  z-index: 5;
}

.multiplayer-result-profile__item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.multiplayer-result-profile__item > div {
  margin-top: -1.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.multiplayer-result-profile__totalpoints {
  color: var(--score-color);
  font-size: 1.5rem;
  font-weight: bold;
}

.multiplayer-result-profile__position {
  position: absolute;
  z-index: 5;
  bottom: 3.5rem;

  width: 32.52px;
  height: 33.86px;

  padding: 2.93px 10.26px;

  border-radius: 131.92px;
  font-weight: bold;
  background-color: var(--light-blue);
  color: var(--score-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.multiplayer-result__user-score {
  max-width: 1021px;
  width: 100%;

  border-radius: 8px;
  border: 0.5px;
  padding: 1rem 0 3rem 0;

  background-color: var(--blue-base);
  z-index: 3;
  margin-top: -5.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.multiplayer-result__user-score__item {
  background: var(--score-gradiant);

  max-width: 250px;
  width: 100%;

  border-radius: 10.56px;
  border: 0.93px;
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
}

.multiplayer-result__user-score__item-img-con {
  width: 42.24px;
  height: 42.24px;

  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.26) 100%
  );

  border-image-source: linear-gradient(
    93.78deg,
    rgba(255, 255, 255, 0.21) 8.04%,
    rgba(255, 255, 255, 0) 100%
  );

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.multiplayer-result__user-score__item-img-con img {
  width: 25px;
  image-rendering: crisp-edges;
  object-fit: cover;
}

.multiplayer-result__user-score-pair {
  display: flex;
  width: 100%;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.multiplayer-result__user-score__item-number {
  font-weight: bold;
}

.multiplayer-result__user-score__item-question-text {
  color: #a0aec0;
}

.multiplayer-result__user-score__item-correct-text {
  color: #4bc257;
}

.multiplayer-result__user-score__item-incorrect-text {
  color: #e83333;
}

.multiplayer-result__options {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3rem;
  max-width: 1021px;
  width: 100%;
}

.multiplayer-result__options-item {
  display: flex;

  flex-direction: column;
  align-items: center;
  gap: 0.77rem;
  cursor: pointer;
}

.multiplayer-result__top-three {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.topper-2 .profilePic,
.topper-2 .profilePic__ring,
.topper-2 .profilePic__ring img,
.topper-3 .profilePic,
.topper-3 .profilePic__ring,
.topper-3 .profilePic__ring img {
  width: 150px;
  height: 150px;
}

.topper-2 .profilePic__item,
.topper-3 .profilePic__item {
  width: 100px;
  height: 100px;
  transform: translate(-50%, -47%);
}

.multiplayer-result-profile__topper-2,
.multiplayer-result-profile__topper-3 {
  gap: 2rem;
}

.multiplayer-result-profile__topper-details-2,
.multiplayer-result-profile__topper-details-3 {
  margin-top: -2.2rem !important;
}

.multiplayer-result-profile__totalpoints-2,
.multiplayer-result-profile__totalpoints-3 {
  color: #ffffff;
}

.muliplayer-table-head,
.muliplayer-table-row {
  display: flex;
  width: 100%;
}

.muliplayer-table-row {
  box-shadow: 0px 1.33px 0px 0px #349aff5c;
  padding: 1rem 0.85rem;
  align-items: center;
}

.others-table-row {
  background-color: var(--dark-blue);
}

.own-table-row {
  background-color: var(--light-blue);
  border-radius: 15.99px;
}

.muliplayer-table-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  max-width: 1021px;
  width: 95%;
}

.muliplayer-table-head__item,
.muliplayer-table-row__item {
  border: 0.53px solid var(--light-blue);
  width: 100%;
  padding: 5.33px;
  text-align: center;
}

.muliplayer-table-head__item {
  text-align: left !important;
}

.muliplayer-table-row__item {
  border: none;
  display: flex;
  gap: 0.8rem;
  align-items: center;
}

.muliplayer-table-row__item-position {
  position: relative;
}

.muliplayer-table-row__item-img-con img {
  width: 57.1px;

  border-radius: 15.99px;
  object-fit: cover;
  image-rendering: crisp-edges;
}

.muliplayer-table-row__item-position span {
  position: absolute;
  top: 0.65rem;
  left: 0.8rem;
  font-weight: bold;
}

.reply-game-con {
  background-color: var(--dark-blue);
  box-shadow: 0px 1px 3px 0px #1018281a;

  box-shadow: 0px 1px 2px -1px #1018281a;

  width: 626px;
  height: 384px;

  overflow-y: auto;
  padding: 1rem;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.reply-game-btns {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 320px;
  width: 100%;
  align-items: center;
}

.reply-game-btns .accept {
  background-color: var(--secondary-color);
  color: #ffffff !important;
}

.reply-game-btns .decline {
  background-color: transparent;
  color: #ffffff !important;

  border: 1px solid #e83333;

  outline: none;
}
