:root {
  --primary-color: #000b5b;
  --dark-blue: #000036;
  --secondary-text: #9b9b9f;
  --dark-text: #272d37;
  --light-text: #5f6d7e;
  --darker-text: #111111;

  --mid-color: #000b5b;
  --fade-text: #999999;
  --fade-type2: #969696;
  /* --dark-blue: #000b5b; */
  --input-bg: #a5a5a559;
  --border-ring: #755efa;

  --primary-btn-color: #437ef7;
  --orange: #ff822d;
  --secondary-color: #349aff;
  --correct-answer-border: #009c00;
  --correct-answer: #34e234;
  --wrong-answer: #ff3232;
  --no-answer: #ffffff;
  --score-color: #ffb800;
  --correct-bg-20: #34e23433;
  --wrong-bg-12: #fd38381f;

  --text-mid: #9b9b9f;
  --gray-border: #878696;
  --light-blue: #349aff;
  --light-orange: #e3af61;

  --pure-blue: #143b82;
  --blue-base: #143b82;
  --rear-blue: #437ef7;
  --fade-text-2: #acacb2;

  --red-dot: #f00d0d;

  /* Asthetics */
  --border-gradient: linear-gradient(100.98deg, #e361e1 14.41%, #6691e9 98.86%);
  --background-gradient: linear-gradient(
    100.98deg,
    #e361e1 14.41%,
    #6691e9 98.86%
  );
  --background-gradient-blue: linear-gradient(
    100.98deg,
    #6166e3 14.41%,
    #6691e9 98.86%
  );

  --gradiate-card-bg: linear-gradient(
    159.79deg,
    rgba(117, 105, 222, 0.6) 13.46%,
    rgba(46, 31, 175, 0.6) 108.12%
  );

  --score-gradiant: linear-gradient(
    90deg,
    rgba(52, 154, 255, 0.7) -1.57%,
    rgba(39, 86, 146, 0.7) 100%
  );
}

html,
*,
*::before,
*::after {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: var(--dark-blue);
  /* background-color: var(--primary-color); */

  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  scroll-behavior: smooth;
}

a {
  background-color: transparent;
}

h1 {
  font-size: clamp(2rem, 5vw, 4rem);
}

h2 {
  font-size: clamp(1.5rem, 3vw, 3rem);
}

h3 {
  font-size: clamp(1.25rem, 2vw, 2.5rem);
}

h4 {
  font-size: clamp(1rem, 1.5vw, 2rem);
}

h5 {
  font-size: clamp(0.875rem, 1vw, 1.5rem);
}

p {
  font-size: clamp(1rem, 1vw, 1.25rem);
  line-height: 26px;
}

.red-dot {
  color: var(--red-dot);
}

.options__input-item {
  background-color: transparent;
  height: 55px;
  padding-left: 0.8rem;
  border-radius: 8px;
  color: #ffffff;
  width: 100%;
}

@media screen and (max-width: 575px) {
  ::-webkit-input-placeholder {
    color: inherit;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: inherit;
    opacity: 1;
  }

  ::placeholder {
    color: inherit;
    opacity: 1;
  }
}

.container {
  max-width: 1554px;
  width: 93%;
  margin: 0 auto;
}

.removeView {
  display: none;
}

.delete-option {
  color: #e83333;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.35);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
}

button,
.button {
  cursor: pointer;
  height: 56px;
  padding: 1.2rem 2rem;
  border-radius: 8px;
  max-width: 250px;
  width: 100%;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  text-decoration: none;
}

.gradiat-bg {
  background: linear-gradient(100.98deg, #e361e1 14.41%, #6691e9 98.86%);
}

.gradiat-border {
  max-width: 250px;
  align-self: center;
  padding: 1rem 2rem;
}

.gradiat-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px; /* Same border radius */
  padding: 1px; /* Space for the border */
  background: var(--background-gradient);
  -webkit-mask: /* Ensures gradient is shown only on the border */ linear-gradient(
        #fff 0 0
      )
      content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude; /* Ensure the gradient is shown only on the border */
}

.gradiat-blue-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px; /* Same border radius */
  padding: 1px; /* Space for the border */
  background: var(--background-gradient-blue);
  -webkit-mask: /* Ensures gradient is shown only on the border */ linear-gradient(
        #fff 0 0
      )
      content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude; /* Ensure the gradient is shown only on the border */
}

/* General autofill styles for different states */
/* Autofill styles with rgba for different states */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px rgba(165, 165, 165, 1) inset !important;
  box-shadow: 0 0 0 1000px rgba(165, 165, 165, 1) inset !important;
  -webkit-text-fill-color: #ffffff !important; /* Optional: Set the text color */
}

input:-moz-autofill {
  box-shadow: 0 0 0 1000px rgba(165, 165, 165, 0.35) inset !important;
  -moz-text-fill-color: #ffffff !important; /* Optional: Set the text color */
}

input:-ms-autofill {
  box-shadow: 0 0 0 1000px rgba(165, 165, 165, 0.35) inset !important;
  -ms-text-fill-color: #ffffff !important; /* Optional: Set the text color */
}

/* Utils */
.auth-btn {
  background-color: var(--light-blue);
  min-width: 100% !important;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border: none;
  font-weight: bold;
}

.btn-border {
  border: 1.03px solid var(--secondary-color);
  min-width: 100% !important;
  background-color: transparent;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: bold;
}

.not-for-current-sprint {
  display: none !important;
}

.remove-from-display {
  display: none !important;
}

.remove-default-input:focus,
.remove-default-input:active {
  outline: none;
}

.red {
  color: var(--red-dot);
}

.backdrop {
  position: absolute;
  overflow-y: auto;
  width: 100%;
  min-height: 100vh;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 5;
}

.gradient-text-rainbow {
  background-image: linear-gradient(100.98deg, #e361e1 14.41%, #6691e9 98.86%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Note
- -- Media Query
   ---  450px/550px/640px --- Mobile
   --- 950px  ---- Tablet/ Mobile
   --- 1554px  ---- Laptop
*/

.visible {
  visibility: visible !important;
}

/*  ANIMATIONS  */

/* Rightt to left */
.animate-slide-in-right {
  animation: slideInFromRight 0.7s ease-out forwards; /* Adjust duration and timing function as needed */
}

/* Define the keyframes for the animation */
@keyframes slideInFromRight {
  from {
    transform: translateX(100vw);
    opacity: 0;
  }
  to {
    visibility: visible;
    transform: translateX(0);
    opacity: 1;
  }
}

/* Left to right */
.animate-slide-in-left {
  animation: slideInFromLeft 0.7s ease-out forwards; /* Adjust duration and timing function as needed */
}

/* Define the keyframes for the animation */
@keyframes slideInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-100vw);
  }
  to {
    visibility: visible;
    transform: translateX(0); /* Move to its default position */
    opacity: 1;
  }
}

/* LScale out */
.scale-out {
  animation: scaleOut 0.7s ease-out forwards; /* Adjust duration and timing function as needed */
}

/* Define the keyframes for the animation */
@keyframes scaleOut {
  from {
    transform: scale(0);
  }
  to {
    visibility: visible;
    transform: scale(1);
  }
}

/* LScale out */
.scale-out-instant {
  animation: scaleOutInstant 0.3s ease-out forwards; /* Adjust duration and timing function as needed */
}

/* Define the keyframes for the animation */
@keyframes scaleOutInstant {
  from {
    transform: scale(0);
  }

  to {
    visibility: visible;
    transform: scale(1);
  }
}
