.dashboard-profile-con {
  display: flex;
  flex-direction: column;
  gap: 6rem;
  padding-bottom: 4rem;
}

.dashboard-profile-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
}

.dashboard-profile-header p {
  font-weight: bold;
  font-size: 1.4rem;
}

.dashboard-profile-header__btn {
  background-color: var(--secondary-color);
  color: #ffffff;
}

.dashboard-profile__main {
  display: flex;
  gap: 6rem;

  justify-content: space-between;

  align-items: center;
}

.dashboard-profile__main-profile-id {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Image container */
.dashboard-profile__main-profile-id div {
  width: 187px;
  height: 186px;
  border: 2px dashed gray;
  border-radius: 4px;
  position: relative;
}

.dashboard-profile__main-profile-id div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dashboard-profile__main-profile-id div span {
  position: absolute;
  bottom: -15px;
  right: -20px;
  z-index: 2;
  cursor: pointer;
}

.dashboard-profile__main-form-con {
  max-width: 950px;
  width: 100%;
}

.dashboard-profile__main-form-con form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.dashboard-profile__main-form-con form input {
  width: 100%;
  height: 64px;
  border: 0.4px solid #ffffff !important;
}

.form-deleete-account {
  text-align: center;
  font-weight: 400;
  font-size: 1.7rem;
  cursor: pointer;
  max-width: 200px;
  margin: 0 auto;
  margin-top: 3rem;
}

.dashboard-profile__main-form-con input {
  background-color: transparent !important;
}

.speccialty-con {
  width: 100%;
  position: relative;
}

.select-input-custom {
  width: 100%;
  height: 64px;
  top: 29px;
  padding: 8px 0px 0px 0px;
  gap: 0px;
  border-radius: 4px;
  cursor: pointer;

  border: 0.4px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.7rem;
}

.select-input-custom svg {
  margin-top: 5px;
}

.specialty-drop {
  margin-top: 2px;
  border-radius: 4px;
  height: 160px;
  border: 0.4px solid #ffffff;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  background-color: var(--mid-color);
  z-index: 10;
}

.specialty-drop p {
  padding: 0 0.8rem;
}

.profile-country-state {
  display: flex;
  gap: 3rem;
  width: 100%;
  position: relative;
}

.profile-country-state .auth-input-con {
  width: 100%;
}

.profile-country-state__drop-down {
  height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 0;
  background-color: var(--mid-color);
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  border: 0.4px solid #ffffff;
  z-index: 10;
}

.profile-country-state__drop-down p {
  cursor: pointer;
  padding: 0 0.9rem;
}

.profile-country-state__item {
  width: 100%;
}

.form-hide {
  display: none;
}

@media screen and (max-width: 1337px) {
  .prrofile-country-state {
    flex-wrap: wrap;
    gap: 1.3rem;
  }
}

@media screen and (max-width: 1169px) {
  .dashboard-profile__main {
    flex-wrap: wrap;
    gap: 2.3rem;
  }
}
