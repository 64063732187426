.multiplayer-waiting-con {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  gap: 3rem;
  padding-top: 3rem;
}
.multiplayer-waiting__back {
  align-self: flex-start;
  cursor: pointer;
}

.multiplayer-waiting-content {
  display: flex;
  align-items: center;
  gap: 3rem;
  animation: scaler 1.11s ease-in-out infinite;
}

.multiplayer-waiting-content p {
  font-size: 1.45rem;
  transform: rotate(-25deg); /* Rotate the text 45 degrees */
  transform-origin: top left;
}

.multiplayer-waiting-players-con {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

.multiplayer-waiting-players-con-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

/* Animation */
@keyframes scaler {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}
