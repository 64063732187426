.faq {
  width: 100%;
}

.faq__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  padding: 3rem 0 4rem 0;
}

.faq__header h1 {
  text-align: center;
  max-width: 900px;
  width: 100%;
}

.faq__header p {
  max-width: 940px;
  text-align: center;
}

.faq__con {
  background-color: #ffffff;
  color: var(--dark-text);
  padding: 2rem 0 3rem 0;
}

.faq__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

.faq__main-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.faq__main-header p {
  max-width: 750px;
}

.faq__items {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.faq__item {
  width: 100%;

  padding: 12px 24px 12px 24px;
  gap: 8px;
  border-radius: 6px;
  border: 1px;
  border: 1px solid #dae0e6;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
}

.faq__item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.faq__item-body {
  line-height: 1.5rem;
  max-height: 300px;
  display: none;
}

.active-faq {
  display: block;
}
