.question-detials {
  max-width: 950px;
  width: 100%;
  background: var(--dark-blue);
  border-radius: 18px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.question-detials__header {
  display: flex;
  flex-direction: column;
  gap: 0.55rem;

  align-items: center;
}

.question-detials__header .question-detials h4 {
  text-align: center;
}

.question-detials__durations {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.question-detials__durations-item {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.question-detials__durations-item__img {
  width: 40px;
  height: 40px;
}

.question-detials__durations-item__img img {
  min-width: 100%;
  height: inherit;
}

.question-detials__durations-item__duration {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.question-detials__durations-item__duration .desc {
  color: var(--fade-text);
}

.question-detials__btn {
  border: none;
  outline: none;
  background-color: var(--primary-btn-color);
  color: #ffffff;
  align-self: center;
}

.question-detials__list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-left: 1.5rem;
}
