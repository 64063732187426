.random-button {
  border: 1px solid #349aff;
  color: #349aff;
  border-radius: 12px;
  background-color: #000b5b;
  width: 111px;
}

.tab-circle {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 2px solid #e3af61;
}

.tab-item {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.tab-item p {
  font-size: 20px;
}

.active-tab {
  background-color: #349aff;
}

.ai-summary {
  background: linear-gradient(to right, #313a5be5, #21273de5);
  border: 1.63px solid #a5acba;
  border-radius: 26.09px;
  width: 390px;
  height: 153.3px;
  padding: 26.09px 13.04px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.ai-summary img {
  width: 32px;
  height: 32px;
}
.ai-summary p {
  font-size: 20px;
}

.btn {
  background: linear-gradient(to right, #74747440, #52525240);
  border: 1px solid #349aff;
  width: 171px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  align-items: center;
  cursor: pointer;
}

/* Responsive: medium screens */
@media (max-width: 768px) {
  .ai-summary {
    width: 250px;
    height: 120.3px;
    padding: 26.09px 13.04px;
  }

  .ai-summary img {
    width: 22px;
    height: 22px;
  }

  .ai-summary p {
    font-size: 16px;
  }

  .tab-circle {
    width: 20px;
    height: 20px;
  }
  .tab-item p {
    font-size: 16px;
  }
}
