.dashboard-add-question {
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.dashboard-add-question__header {
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  align-items: center;
}

.dashboard-add-question__header-left {
  display: flex;
  gap: 1rem;

  align-items: center;
}

.question-add__btn {
  background-color: var(--secondary-color);
  color: #ffffff;
}

.dashboard-add__main-content {
  display: flex;
  gap: 4rem;
  justify-content: space-between;
  flex-wrap: wrap;
}

.dashboard-add__main-content-left {
  max-width: 372px;
  width: 100%;
  padding: 1rem;
  max-height: 780px;
  overflow-y: auto;

  border-radius: 12px;
  background: var(--pure-blue);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.dashboard-add__main-content-left .add-question {
  background-color: var(--secondary-color);
  height: 35px;
  max-width: 250px;
  width: 100%;

  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 0.7rem;
  border-radius: 8px;
}

.dashboard-add__main-content-left .add-question p {
  font-size: 1rem;
}

.dashboard-add__main-content-left .search-question {
  border: 2px solid var(--secondary-color);
  display: flex;
  gap: 1.2rem;
  align-items: center;
  padding-left: 0.9rem;
  height: 45px;
  border-radius: 8px;
}

.dashboard-add__main-content-left .search-question input {
  border: none;
  background-color: transparent;
  height: 100%;
  width: 100%;
  color: #ffffff;
}

.dashboard-add__main-content-left .search-question svg {
  cursor: pointer;
}

.dashboard-add__main-content-left .search-question input:focus,
.dashboard-add__main-content-left .search-question input:active {
  outline: none;
}

.dashboard-add__main-content-left .ai-generated-features {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.ai-generated-features__add-question {
  display: flex;
  gap: 1.2rem;
  flex-wrap: wrap;
  align-items: center;
  background-color: var(--secondary-color);
  border-radius: 4px;
  padding: 1rem 0.5rem;
  max-width: 274px;
  width: 100%;

  justify-content: center;
}

.ai-generated-features__refine-prompt {
  max-width: 224px;
  width: 100%;
  cursor: pointer;

  padding: 12px 8px;

  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--secondary-color);
}

.ai-generated-features__show-answer {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  align-items: center;
}

.ai-generated-features__flag-question {
  display: flex;
  gap: 2rem;
  cursor: pointer;
  align-items: center;
}

.question-items {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.question-item {
  display: flex;
  flex-direction: column;
  gap: 1.1rem;
}

.question-item__topic {
  border: 2px solid var(--secondary-color);
  padding: 0.8rem 0.8rem 1.3rem 0.8rem;
  border-radius: 8px;
}

.dashboard-add__main-content-right {
  width: 100%;
  max-width: 650px;
}

.dashboard-add__main-content-right__item {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 100%;
}

.question-add__question-header-con {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  align-self: flex-end;
}

.question-add__question-header-con .header-option {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.question-add__questions {
  border-radius: 20px;
  background: var(--pure-blue);
}

.add-question__svg {
  cursor: pointer;
}

.question-add__question-item {
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 0.9rem;
  color: #ffffff;
  min-height: 170px;
}

.question-add__questions-image {
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
  padding-bottom: 0.4rem;
  align-self: flex-end;
  border-radius: 0 0 20px 20px;
}

/* imagee icon */
.question-add__questions-image__svg {
  cursor: pointer;
}

.grouped-question-image__img-con {
  max-width: 250px;
  width: 100%;
  max-height: 240px;
  overflow-y: auto;
}

.grouped-question-image__img-con img {
  width: 100%;
  object-fit: cover;
  object-position: 50% cover;
  image-rendering: crisp-edges;
}

.question-add-upload-image {
  display: none;
}

.grouped-question-images {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  align-items: flex-start;
}

.grouped-question-image {
  position: relative;
}

.grouped-question-image__svg {
  position: absolute;
  z-index: 3;
  right: 5px;
  top: 15px;
  background-color: var(--secondary-color);
  border-radius: 8px;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.question-add__question-item::placeholder {
  color: #ffffff;
}

.question-add__question-item:focus,
.question-add__question-item:active,
.question-add__question-item:hover {
  outline: none;
  border: none;
}

.dashboard-add__show-multiple-answer {
  display: flex;
  gap: 3rem;
  align-items: center;
}

.btn-add-question {
  background-color: var(--secondary-color);
  color: #ffffff;
  padding: 0.4rem;
  height: 42px;
  max-width: 240px;
  width: 100%;
  outline: none;
}

.add-question-delete {
  color: var(--wrong-answer);
  font-size: 1.3rem;
  align-self: flex-end;
}

.dashboard-add__main-content-right .explaination-ref {
  display: flex;
  align-items: flex-start;
  gap: 3rem;
  flex-wrap: wrap;
  justify-content: space-between;
}

.explaination-ref__item {
  max-width: 450px;
  width: 100%;
  background: var(--pure-blue);

  border-radius: 15px;
}

.explaination-ref__item p {
  padding: 0.6rem;
  width: 182px;
  height: 42px;
  color: #ffffff;
  border-radius: 15px 15px 15px 0px;
}

.dashboard-add__main-content-right .explaination-ref textarea {
  min-height: 200px;
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 1rem;
  color: #ffffff;
}

.dashboard-add__main-content-right .explaination-ref textarea::placeholder {
  color: #ffffff;
}

.explaination-ref__item-explain {
  background-color: var(--secondary-color);
}

.explaination-ref__item-ref {
  background-color: var(--light-orange);
}

.ai-generated-features__textarea-modal {
  max-width: 550px;
  width: 100%;
  padding: 2rem 1.5rem;
  background-color: var(--dark-blue);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.ai-generated-features__prompt-textarea {
  border: 0.5px solid #d9d9d9;
  border-radius: 4px;
}

.ai-generated-features__prompt-textarea-icons__item {
  cursor: pointer;
}

.ai-generated-features__header {
  display: flex;
  gap: 1rem;

  align-items: center;
}

.ai-generated-features__prompt-textarea textarea {
  background-color: transparent;
  width: 100%;
  color: #ffffff;
  padding: 10px;
  border: none;
}

.ai-generated-features__prompt-textarea-icons {
  display: flex;
  gap: 1.3rem;
  align-items: center;
  margin: 1rem;
  justify-content: center;
}

.form-main-ai__prompt-textarea-icons__item {
  cursor: pointer;
}

.ai-generated-features__prompt-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 15px;
}

.ai-generated-features__prompt-btn span {
  cursor: pointer;
}

@media screen and (min-width: 1554px) {
  .dashboard-add__main-content-right {
    max-width: 950px;
    width: 100%;
  }
}
