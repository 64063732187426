.quiz-result {
  background-color: var(--secondary-color);
  max-width: 845.61px;
  width: 95%;
  padding: 2rem 1rem;
  border-radius: 32px 32px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.8rem;
  position: relative;
  background-image: url('../../assets/images/start-pattern-landscape.png');
}

.quiz-result__header {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  position: relative;
}

.quiz-result-close-modal {
  position: absolute;
  z-index: 2;
  right: 0;
  cursor: pointer;
}

.quiz-result__header-contents {
  margin-top: -1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.quiz-result__score {
  color: var(--score-color);
  font-weight: bold;
  font-size: 1.7rem;
}

.quizz-result-btns {
  display: flex;
  gap: 2rem;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.quizz-result-btns .continue-btn {
  color: #ffffff;
  background-color: var(--dark-blue);
  border: 1px solid var(--dark-blue);
}

.quizz-result-btns .review-btn {
  color: #ffffff;
  background-color: transparent;
  border: 1px solid #61c7ff;
}
