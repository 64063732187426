.feedback-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.ai-summary-text {
  font-size: 16px;
  font-weight: 400;
}

.profile-image-container {
  z-index: 10;
  width: 160px; /* Diameter of the container */
  height: 160px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  overflow: hidden;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  /* background-image: url("../../../../public/images/medSynopsis/med-profile-ring.png"); */
}

.profile-image {
  width: 85%;
  height: 85%;
  object-fit: cover;
  border-radius: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.score-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #d9d9d933;
  width: 182.16px;
  height: 185.27px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 10px;
  margin-top: -90px;
}
.score-container p {
  font-size: 21px;
}
.score-container span {
  font-size: 31.14px;
  color: #ffb800;
  font-weight: 700;
}

.points-container {
  background-color: #349aff;
  padding: 5px 5px;
  margin-top: -20px;
  border-radius: 10px;
  width: 150.16px;
  text-align: center;
}
.points-container p {
  font-size: 21.8px;
  font-weight: 700;
  color: #ffb800;
}
.points-container span {
  font-size: 18.68px;
  font-weight: 400;
}

.record-container {
  background-color: #000b5b;
  padding: 10px;
  width: 382px;
  border: 1px solid #eaebf0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.record-container div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.record-container p {
  font-size: 18.68px;
  font-weight: 400;
}

.record-container span {
  font-size: 21.8px;
  font-weight: 600;
}
.record-container small {
  font-size: 18.68px;
  font-weight: 600;
}

.feedback-item {
  padding: 10px 20px;
  cursor: pointer;
}

.active-feedback {
  border: 1px solid #b8b8b8;
}

.filter-item-active {
  position: relative;
  background: linear-gradient(to right, #e361e12b, #6691e92b);
  color: white;
  cursor: pointer;
}
.filter-item {
  display: "block";
  position: relative;
  color: white;
  background-color: "#7B7B7B";
  align-self: center;
  padding: 1rem 2rem;
  cursor: pointer;
  border: 1px solid #7b7b7b;
  border-radius: 10px;
}

.summary-modal {
  width: 50%;
  background-color: #000036;
  height: 300px;
  overflow-y: auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 10px;
  position: relative;
}

.summary-container {
  min-height: 200px;
  max-height: 350px;
  overflow-y: auto;
  width: 80%;
  border: 1px solid #7b7b7b;
  border-radius: 8px;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  /* Customize scrollbar */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #7b7b7b;
    border-radius: 3px;
  }
}

@media (max-width: 768px) {
  .record-container {
    width: 300px;
  }
  .record-container p {
    font-size: 14px;
  }

  .feedback-item {
    font-size: 14px;
    padding: 5px 10px;
  }

  .summary-modal {
    width: 90%;
  }
  .summary-modal p {
    font-size: 14px;
  }

  .record-container span {
    font-size: 16.8px;
  }
  .record-container small {
    font-size: 14.68px;
  }

  .ai-summary-text {
    font-size: 14px;
    font-weight: 400;
  }
}
@media (max-width: 425px) {
  .record-container {
    width: 260px;
    padding: 10px;
  }

  .summary-container {
    width: 100%;
  }
}
