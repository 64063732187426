.multipler-con {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.multipler-con form {
  max-width: 750px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.multipler-con form .auth-input-con input,
.multipler-con form .select-input-custom {
  height: 64px;
  border: 0.8px solid #7b7b7b;
}

.multipler-con form .specialty-drop p {
  cursor: pointer;
}

.multipler-con form .specialty-drop {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.multipler-con form .specialty-drop ::-webkit-scrollbar {
  display: none;
}

.multipler-con__btn {
  background-color: var(--secondary-color);
  color: #ffffff;
  min-width: 100%;
}

.subcategory-modal {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  background-color: var(--dark-blue);
  max-width: 750px;
  width: 100%;
  padding: 2rem 1rem;
  border-radius: 15px;
  flex-wrap: wrap;
}

.subcategory-modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-multipler-view {
  background-color: var(--secondary-color);
  color: #ffffff;
  max-width: 200px;
}

.subcategory-modal__main {
  display: flex;
  gap: 3rem;
  flex-wrap: wrap;
}

.subcategory-modal__item {
  border: 2px solid var(--secondary-color);

  max-width: 220px;
  max-height: 280px;

  width: 100%;

  padding: 2rem 0.9rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
}

.subcategory-modal__item-header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.subcateegory-check {
  border: 1px solid var(--orange);
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.subcategory-modal__item .text-title {
  text-align: center;
  font-weight: bold;
}
