.dashboard-home {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.dashboard {
  display: flex;
  gap: 3rem;
  height: 100vh;
}

.dashboard .sidebar {
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 12px 11px 21px 0px #349aff17;
  border-right: 0.5px solid #349aff;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: center;
  padding: 0 4rem;
  padding-top: 1rem;
}

.dashboard-navbar__logo {
  cursor: pointer;
}

/* .main-outlet {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */

.dashboard-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.dashboard .outlet {
  width: 90%;
  padding-right: 1rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 1750px;
  margin: 0 auto;
}

.activeColor {
  color: #349aff;
}

.inactiveColor {
  color: #cfcfdf;
}

.outlet {
  padding-top: 2rem;
}

.outlet__header {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;
  border-bottom: 0.5px solid #349aff;
}

.outlet__header-left {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.outlet__header-left__indicators {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 3rem;
}

.outlet__header-left__toggle-menu {
  display: none;
}

.outlet__header-left__toggle-menu-item-close,
.outlet__header-left__toggle-menu-item-open {
  width: 30px;
  height: 30px;
}

.outlet__header-right,
.dashboard__profile,
.dashboard__profile-name {
  display: flex;
  gap: 3rem;
  align-items: center;
}
.outlet__header-right {
  flex-wrap: wrap;
}

.dashboard__profile {
  gap: 1rem;
}

.dashboard__profile-name {
  gap: 1rem;
  cursor: pointer;
}

.dashboard__profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.dashboard__joinGame {
  position: relative;
  padding: 0.7rem 3rem;
  cursor: pointer;
}

.dashboard__profile-image img {
  width: 100%;
  height: inherit;
  border-radius: 50%;
  border: 2px solid var(--border-ring);
  image-rendering: crisp-edges;
  object-fit: cover;
  object-position: 50%;
}

.dashboard-home-btn {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.mobile-dashbord-show-modal,
.show-modal {
  display: none !important;
}

.show-logout {
  display: none;
}

@media screen and (max-width: 950px) {
  .show-logout {
    display: flex;
  }
}

@media screen and (max-width: 550px) {
  .show-modal {
    display: block !important;
  }

  .dashboard .sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: var(--dark-blue);
    z-index: 10;
    transition: all 0.55s ease-in-out;
  }

  .position-hide {
    left: -100% !important;
    display: none;
  }

  .position-show {
    left: 0 !important;
    display: flex !important;
  }

  .outlet__header-left__indicators {
    gap: 1rem;

    justify-content: flex-end;
  }

  .outlet__header-left__toggle-menu {
    display: block !important;
  }

  .dashboard__profile-name {
    display: flex;
  }

  .outlet__header-left {
    align-items: flex-start;
  }
}
