.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-bottom: 30px;
}

.grid-item {
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 400px; /* Default height */
  border-radius: 17.83px;
  position: relative;
  flex-direction: column;
  border: 1px solid #7b7b7b;
  background: linear-gradient(to right, #313a5be5, #21273de5);
  cursor: pointer;
  padding: 10px;
}
.grid-item p {
  font-size: 31.21px;
  line-height: 42.5px;
}

/* Shorter items */
.grid-item.shorter {
  height: 350px;
}

/* Taller items */
.grid-item.taller {
  height: 450px;
  margin-top: -50px; /* Pull up taller items */
}

.quiz-img {
  width: 280px;
  height: 280px;
}

.ai-modal {
  width: 60%;
  background-color: #000036;
  min-height: 60vh;
  max-height: 80vh;
  overflow-y: auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.input-container {
  border: 1px solid #7b7b7b;
  margin-top: 10px;
  height: 48px;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-top: 30px;
}

.input-container input {
  flex: 1;
}

.file-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #7b7b7b;
  border-radius: 10px;
  padding: 10px;
  width: 80%;
  height: 170px;
  border-style: dashed;
  gap: 20px;
  border-width: 3px;
  cursor: pointer;
}

.file-close-button {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #000036;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #7b7b7b;
}

.file-name-container {
  border: 1px solid #7b7b7b;
  padding: 15px;
  border-radius: 8px;
  position: relative;
  width: 80%;
}

.answer-container {
  height: 250px;
  overflow-y: auto;
  width: 90%;
  border: 1px solid #7b7b7b;
  border-radius: 8px;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  padding: 10px;
  /* Customize scrollbar */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #7b7b7b;
    border-radius: 3px;
  }
}

.copy-response-button {
  background-color: #349aff61;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  text-align: center;
}

.loading-spinner {
  animation: spin 1s linear infinite;
  display: inline-block;
  position: relative;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Responsive: medium screens */
@media (max-width: 1024px) {
  .grid-item {
    height: 300px; /* Default height */
  }

  .grid-item p {
    font-size: 20.21px;
    line-height: 42.5px;
  }

  .grid-container {
    grid-template-columns: repeat(2, 1fr); /* 2 items per row */
  }

  .grid-item.shorter {
    height: 250px;
  }

  .grid-item.taller {
    height: 350px;
    margin-top: -50px; /* Pull up taller items */
  }
  .quiz-img {
    width: 200px;
    height: 200px;
  }

  .ai-modal {
    width: 80%;
    padding: 20px;
  }
}

/* Responsive: Small screens */
@media (max-width: 426px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
  }

  .grid-item.taller {
    margin-top: 0px; /* Pull up taller items */
  }

  .ai-modal {
    width: 90%;
  }

  .input-container {
    width: 100%;
  }
  .file-container {
    width: 100%;
  }

  .answer-container {
    width: 100%;
  }
  .copy-response-button {
    font-size: 14px;
  }
}
