.contactus {
  width: 100%;
}

.contactus__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  padding: 3rem 0 4rem 0;
}

.contactus__header h1 {
  text-align: center;
  max-width: 900px;
  width: 100%;
}

.contactus__header p {
  max-width: 940px;
  text-align: center;
}

.contactus__form-con {
  background-color: #ffffff;
  color: var(--dark-text);
  padding: 2rem 0 3rem 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.form__items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.form__item {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}

.form__item label {
  color: var(--dark-text);
}

.form__item input {
  height: 46px;
  padding: 12px 16px 12px 16px;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid #dae0e6;
}

textarea {
  padding: 12px 16px 12px 16px;
  gap: 10px;
  border-radius: 6px;
  display: flex;
  max-width: 100%;
  height: 100px;
  resize: none;
  overflow-y: auto;
  max-height: 467px;
  display: flex;
  border: 1px solid #dae0e6;
}

.form__item input:active,
.form__item input:hover,
.form__item input:focus,
textarea:active,
textarea:hover,
textarea:focus,
.contactus__phone .PhoneInput input:hover,
.contactus__phone .PhoneInput input:active,
.contactus__phone .PhoneInput input:focus {
  outline: none;
  border: 1px solid #dae0e6;
}

.contactus__phone .PhoneInput {
  outline: none;
  height: 46px;
  border: 1px solid #dae0e6;
}

.contactus__phone .PhoneInputCountry {
  padding: 1rem;
}
.contactus__phone .PhoneInput input {
  height: 100%;
  border: 1px solid #dae0e6;
  width: 100%;
  padding: 12px 16px 12px 16px;
  gap: 8px;
  border-radius: 6px;
  border-top-color: transparent;
  border-bottom: transparent;
  border-right: transparent;
}

.form__item-checkbox {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.form__item-checkbox input {
  width: 20px;
  height: 24px;
}

.contactus button {
  box-shadow: 0px 1px 2px 0px #1018280a;
  background-color: var(--primary-btn-color);
  border: 1px solid var(--primary-btn-color);
  color: #ffffff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 950px) {
  .form__items {
    grid-template-columns: 1fr;
  }
}
