.forget-password-con {
  min-height: 100vh;
  background-color: var(--mid-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}

.forget-password-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;
}

.forget-password-main__header {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
}
