.dashboard-create-container {
  display: flex;
  gap: 3rem;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 2rem;
}

.dashboard-create__image-upload {
  border: 2px solid var(--gray-border);
  max-width: 446px;
  width: 100%;
  max-height: 290px;
  height: 100%;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  position: relative;
}

.dashboard-create__image-upload-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dashboard-create__form-area {
  max-width: 450px;
  width: 100%;
}

.dashboard-create__form-area form input {
  width: 100%;
  height: 50px;
  border-radius: 8px;
  padding-left: 10px;
  border: 0.8px solid #7b7b7b;
  background-color: transparent;
  color: #ffffff;
}

.dashboard-create__form-area form input:focus,
.dashboard-create__form-area form input:active {
  outline: none;
}

.dashboard-create__form-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.dashboard-create__form-item-joint {
  display: flex;
  gap: 2rem;
}

.dashboard-create__form-item-joint .dashboard-create__form-item {
  width: 100%;
}

.dashboard-create__btns {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.dashboard-create__btns .btn {
  width: 100%;
}

.dashboard-create__btns .btn button {
  min-width: 100% !important;
  border: none;
}

.dashboard-create__btns .create-btn {
  color: #ffffff;
  background-color: var(--secondary-color);
}

.dashboard-create__btns .ai-btn {
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #e3af61;
  color: #ffffff;
  height: 55px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
}

.dashboard-create__form-item-timer {
  display: flex;
  gap: 2rem;
}

.dashboard-create__update-quiz {
  margin-top: 2rem;
  background-color: var(--secondary-color);
  height: 45px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
  cursor: pointer;
}

.category-quiz {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 0.8px solid #7b7b7b;
  max-height: 200px;
  height: 100%;
  overflow-y: auto;
  padding: 1rem 0;
  border-radius: 8px;
}

.category-quiz__add-cat {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 1rem;
  justify-content: center;
}

.category-quiz__add-cat input {
  width: 85% !important;
}

.category-quiz__add-cat p {
  cursor: pointer;
  text-decoration: underline var(--secondary-color);
}

.category-quiz::-webkit-scrollbar {
  display: none;
  width: 0;
}

.category-quiz__item {
  padding: 1rem;
  cursor: pointer;
}

.category-quiz__item:hover {
  background-color: var(--secondary-color);
}

/* Media Query */

@media screen and (max-width: 1150px) {
  .dashboard-create__form-item-joint {
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 1554px) {
  .dashboard-create__form-area {
    max-width: 660px;
    width: 100%;
  }
}
