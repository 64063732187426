.ai-modal {
  width: 50%;
  background-color: #000036;
  height: 300px;
  overflow-y: auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 10px;
  position: relative;
}

.ai-feedback-summary p {
  font-size: 16px;
}

.ai-modal div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin: auto;
}

.ai-modal h5 {
  font-size: 20px;
  font-weight: 600;
}

.ai-modal p {
  font-size: 16px;
  font-weight: 400;
  color: #9b9b9f;
  text-align: center;
  width: 80%;
}

.subscribe-btn {
  background-color: #349aff61;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  width: 200px;
}

@media (max-width: 1024px) {
  .ai-modal {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .ai-modal {
    width: 90%;
  }

  .ai-modal h5 {
    font-size: 18px;
  }

  .ai-modal p {
    font-size: 14px;
    width: 100%;
  }

  .ai-feedback-summary p {
    font-size: 14px;
  }
}
