.topup-main {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 3rem;
}

.topup-left-container {
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
}

.topup-left-container > p {
  color: #c3c3c3;
}

.topup-right-container {
  max-width: 520px;
  width: 100%;
  padding: 1rem 1.5rem;
  border-radius: 1.3rem;
}

.topup-right-container .stripe-form {
  margin-top: 0.6rem;
  background-color: var(--primary-color);
  padding: 1rem;
  border-radius: 8px;
}

.topup-main_left {
  max-width: 409px;
  width: 100%;
  border-radius: 1.3rem;
  background-color: var(--primary-color);
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.topup-main_left .line-through {
  border: 1px solid var(--fade-type2);
  height: 1px;
  background-color: var(--fade-type2);
  opacity: 0.5;
  margin-top: -1rem;
}

.topup-main_left-row {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: flex-start;
}

.topup-main_left > p,
.topup-main_left-row {
  padding: 0 1.5rem;
}

.topup-main_left-row > p,
.topup-main_left-row-header > p {
  font-weight: bold;
  font-size: clamp(1.2rem, 1vw, 1.5vw);
}

.topup-main_left-row-header {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.topup-main_left-row__counters-item-p {
  font-weight: bold;
  font-size: 1.25rem;
}

.topup-main_left-row__counters {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
}

.topup-main_left-row__counters-item {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.topup-main_left-row__counters-item span:nth-child(2) {
  font-weight: bold;
  font-size: 1.3rem;
  margin-top: 3px;
}

.topup-main_left-row__counter {
  cursor: pointer;
  width: 22px;
  height: 25px;
  padding: 4px;
  gap: 10px;
  border-radius: 4px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topup-main_left-row__counter__active {
  background: linear-gradient(100.98deg, #6166e3 14.41%, #6691e9 98.86%);
}

.topup-main_left-row__counter__inactive {
  background: #a9a9a9;
}

@media screen and (min-width: 1545px) {
  .topup-right-container {
    max-width: 570px;
  }

  .topup-left-container {
    max-width: 530px;
  }
}
