.outlet__header-icon {
  display: flex;
  gap: 1rem;
}

.outlet__header-rate {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.outlet__header-rate-value {
  color: var(--light-blue);
}
