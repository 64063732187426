.profilePic {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.profilePic__ring {
  position: relative;
  z-index: 2;
  width: 200px;
  height: 200px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.profilePic__ring img {
  margin-top: 2rem;
  width: 200px;
  height: 200px;
}

.profilePic__item {
  display: flex;
  align-items: center;
  width: 71%;
  height: 71%;
  position: absolute;
  top: 50%;
  left: 50%;
  clip-path: circle(44.56%);
  transform: translate(-50%, -51%);
}

.profilePic__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
