.marking-scheme-container {
  padding: 10px;
}

@media (max-width: 425px) {
  .marking-scheme-container {
    padding: 5px;
  }
  .marking-scheme-container p {
    font-size: 14px;
  }

  .marking-scheme-container span {
    font-size: 14px;
  }
}
