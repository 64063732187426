.hero {
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  padding: 3rem 0 0 0;
  position: relative;
  z-index: 2;
}

.hero__ai-power {
  position: relative;
  background-color: #262d3491;
}

.hero__ai-power::before {
  border: 1px solid;

  background: linear-gradient(100.98deg, #e361e1 14.41%, #6691e9 98.86%);
  color: #ffffff;
}

.hero_header {
  text-align: center;
  line-height: 2.5rem;
  font-size: 2rem;
}

.typing-hero {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem 0 2.5rem 0;
}

.hero__get-started {
  border-radius: 10px;
  cursor: pointer;
  padding: 1rem 2rem;
  max-width: 230px;
  width: 100%;
  text-align: center;
  background: linear-gradient(100.98deg, #e361e1 14.41%, #6691e9 98.86%);
}

.hero__description {
  text-align: center;
  max-width: 950px;
  width: 100%;
  margin: 0 auto;
  line-height: 2rem;
}

.hero__join-game {
  position: relative;
  cursor: pointer;
  max-width: 260px;
  width: 100%;
  text-align: center;
}

.hero__join-game::before {
  border: 1px solid;
  background: linear-gradient(100.98deg, #6166e3 14.41%, #6691e9 98.86%);
  color: #ffffff;
}

.hero__buttons {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  align-items: center;

  justify-content: center;
}

.hero__robot-container {
  position: relative;

  margin-top: 2rem;

  background: url('../../../../assets/images/basket-line-bg.png') center center
    no-repeat;
  background-size: 60%; /* Adjust as needed */
}

.decor {
  z-index: 1;
}

.hero__robot-container .decor-ellispes {
  max-width: 650px;
  width: 100%;
  position: absolute;
  top: -15rem !important;
}

.decor-galaxy-white {
  margin-left: 1.5rem;
  margin-top: 1.5rem;
  position: absolute;
}

.decor-atom {
  position: absolute;
  margin-left: 1.5rem;
  margin-top: 2.5rem;
}

.decor-galaxy-colored-left {
  position: absolute;
  bottom: 5.5rem;
  left: 10rem;
}

.decor-galaxy-colored-right {
  position: absolute;
  right: 18rem;
  top: 9rem;
}

.decor-connect-bottom-mid {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.decor-connect-right {
  position: absolute;
  right: 1rem;
}

.hero__robot-doc-img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 502px;
}

.hero__robot-doc-img img {
  max-width: 863.12px;
  width: 100%;
  position: relative;
  margin: 0 auto;
  z-index: 4;
  height: auto;
}

.typing-hero [data-testid='react-typed'] span {
  font-size: 1.85rem;
  font-weight: 700;
  background: linear-gradient(100.98deg, #e361e1 14.41%, #6691e9 98.86%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

/* Remove glasses */
@media screen and (max-width: 930px) {
  .typing-hero {
    min-height: 150px;
    margin: 0;
  }

  .typing-hero [data-testid='react-typed'] span {
    font-size: 1.85rem;
  }
}

@media screen and (max-width: 530px) {
  .typing-hero {
    min-height: 185px;
    margin: 0;
  }

  .typing-hero [data-testid='react-typed'] span {
    font-size: 1.65rem;
  }
}
