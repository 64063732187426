.question-bank__card {
  background: var(--gradiate-card-bg);
  width: 320px;

  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border-radius: 8px;

  height: 100%;
}

.question-bank__card-img {
  width: 300px;
  height: 200px;

  cursor: pointer;
}

.question-bank__card-img img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  image-rendering: crisp-edges;
}

.question-bank__card-img:hover {
  transform: scale(1.015);
  transition: all ease-in-out 0.25s;
}

.question-bank__card-content {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  width: 100%;
}

.question-bank__card-content__topic {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
}

.question-bank__card-descrip {
  align-self: flex-start;

  min-width: 100%;
  font-size: 0.92rem;
}

.question-bank__card-more-option-item {
  cursor: pointer;
}

.question-bank__card-more-option-popup {
  background-color: var(--mid-color);
  max-width: 500px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 0.4rem 2rem 2.5rem;
  border-radius: 18px;
}

.question-bank__card-more-option-popup li {
  list-style-type: none;
  padding-bottom: 2rem;
  border-bottom: 0.3px solid #7b7b7b;
  cursor: pointer;
}

.question-bank__card-more-option-popup li:hover {
  border-bottom: 0.3px solid var(--border-ring);
}

@media screen and (max-width: 450px) {
  .question-bank__card {
    max-width: 320px;
    width: 100%;
  }
}
