.question-bank {
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  gap: 4.5rem;
}

.question-bank__headers {
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: space-between;
  flex-wrap: wrap;
}

.question-bank__header-left,
.question-bank__header-right {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.question-title {
  font-weight: 400;
}

.question-bank__header-right {
  display: flex;
  justify-content: flex-end;
}

.question-bank__header-right button {
  min-width: 200px;
  width: 100%;
  align-self: flex-end;
  color: #ffffff;
  position: relative;
  border: none;
}

.question-bank__header-right .multiselect-btn {
  background-color: transparent;
}

.question-bank__header-right .random-btn {
  background-color: var(--primary-btn-color);
}

/* Search and Filters */

.question-bank__search {
  max-width: 660px;
  width: 100%;
  height: 50px;
}

.question-bank__search-filter {
  display: flex;
  align-items: center;
  gap: 4rem;
  flex-wrap: wrap;
  justify-content: space-between;
}

.question-bank__sorts-filters {
  display: flex;
  align-items: center;
  gap: 1.3rem;
}

.question-bank__sort-filter {
  background-color: var(--pure-blue);
  padding: 1rem 2.3rem;
  min-width: 180px;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  gap: 1.4rem;
  justify-content: center;
  align-items: center;
}

/* Purpose Selection */

.question-bank__content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.question-bank__content-selction {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.question-bank__content-selction__item {
  cursor: pointer;
  border-bottom: 3px solid transparent;
}

.question-bank__active-selection {
  border-bottom: 3px solid var(--secondary-color);
}

/* Cards */

.question-bank__cards-categories {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.question-bank__cards-category {
  display: flex;
  flex-direction: column;
  gap: 1.52rem;
}

.question-bank__cards {
  display: flex;
  gap: 1.3rem;
  flex-wrap: wrap;
}

.question-bank-btn {
  align-self: center;
  background-color: var(--secondary-color);
  color: #ffffff;
}

@media screen and (max-width: 450px) {
  .question-bank__header-right {
    justify-content: center;
    flex-wrap: wrap;
  }
}
