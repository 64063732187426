.subscription-main {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  max-width: 1540px;
  width: 95%;
  margin: 0 auto;
}

.table-scroller {
  width: 100%;
}

.subscription-main__header {
  display: flex;
  max-width: 285px;
  width: 100%;
  height: 38px;
  padding: 3px 0;
  gap: 6px;
  border-radius: 5px;
  background: #a5acba;
  align-self: center;
}

.starter-bg-color {
  background: #e3af61 !important;
}

.pro-bg-color {
  background: #349aff;
}

.premium-bd-color {
  background: #9c62db !important;
}

.subscription-main__header span {
  box-shadow: 0px 1px 2px 0px #1018280a;
  flex: 1;
  padding: 9px 18px;
  border-radius: 5px;
  color: #5f6d7e;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  transition: color 0.7s ease, background 0.7s ease;
}

.subscription-table__th-item {
  position: relative;
}

.subscription-table__th-item-selected {
  position: absolute;
  top: -2.7rem;

  left: 0;

  width: 100%;
  border-radius: 8px 8px 0px 0px;
  color: #fff;
  background-color: var(--light-blue);
  display: flex;
  align-items: center;
  height: 46px;
  justify-content: center;
  visibility: visible;
  z-index: 2;
}

.hide-plan-selection {
  visibility: hidden;
}

.cursored {
  cursor: pointer;
}

.table-row,
.price-table-row {
  display: flex;
  border-bottom: 0.2px solid #ffffff;

  height: 50px;
}

.table-row__item {
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 0px;
  transition: padding 0.5s ease;
}

.active-subscription-header {
  background-color: var(--light-blue);
  color: #fff !important;
  padding-left: 12px;
}

.table-row__item p {
  font-weight: bold;
}

.table-row__item-price p {
  font-size: clamp(1.5rem, 1.45vw, 2.3rem);
  font-weight: bold;
}

.subscription--stripe-container .stripe-main-chekout {
  max-width: 650px;
  width: 100%;
  margin: 0 auto;
  margin-top: 4rem;
}

.subscription--stripe-container .stripe-form {
  margin-top: 0.6rem;
  background: #143b82;
  padding: 1rem;
  border-radius: 8px;
}

.subscription--stripe-container .stripe-form .form-stripe__btn {
  max-width: 424px;
  width: 90%;
  margin: 0 auto;
}

.subscription--stripe-container .form-stripe__item .StripeElement {
  min-width: 100%;
  line-height: 65px;
}

.subscription--stripe-container .stripe-img-card-con img {
  width: 60%;
}

.subscription--stripe-container .form-stripe__item {
  min-width: 260px;
}

@media screen and (max-width: 950px) {
  .subscription-table {
    min-width: 750px;
  }
  .table-scroller {
    padding-top: 5rem;
    max-width: 560px;
    overflow-x: auto !important;
  }
}
