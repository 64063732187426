.quizz-trivia {
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  margin-top: -1rem;
  padding-bottom: 2rem;
}

.quiz-trivia__bars {
  display: flex;
  justify-content: flex-start;
}

.quizz-trivia .countDown {
  display: flex;
  justify-content: center;
  width: 100%;
}

.trivia-quizz-con {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.trivia-quizz-question {
  min-width: 100%;
  min-height: 140px;
  gap: 0px;
  border-radius: 28px;
  border: 4px solid var(--secondary-color);

  background: #143b82;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: 0.4rem 0.88rem;
}

.trivia-quizz-options {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.trivia-quizz-option {
  min-width: 100%;

  height: 72px;

  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #ffffff;
  color: #000000;
  padding-left: 0.65rem;
  cursor: pointer;
}

.quiz__correct-correct {
  background-color: var(--correct-answer);
  color: #ffffff;
}

.quiz__wrong-wrong {
  background-color: var(--wrong-answer);
  color: #ffffff;
}

.quiz__correct-answer {
  border: 2px solid var(--correct-answer-border);
}

.no-question-error {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6rem;
  gap: 2.5rem;
}

.no-question-error p {
  font-size: 1.5rem;
  text-align: center;
}

.no-question-error_btn {
  border: none;
  background-color: var(--secondary-color);
  color: #ffffff;
}
