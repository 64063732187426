.multiplayer-trivia {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.multiplayer-trivia-points {
  display: flex;
  align-items: flex-start;
  gap: 3rem;
  justify-content: space-between;
}

.multiplayer-trivia-points__items {
  display: flex;

  gap: 0.8rem;
  align-items: center;
  flex-wrap: wrap;
}

.multiplayer-trivia-points__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
}

.multiplayer-trivia-points__item-img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 2px solid var(--border-ring);
  object-fit: cover;
  image-rendering: crisp-edges;
}

.multiplayer-trivia-quiz {
}
