.review {
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  padding-bottom: 2rem;
  gap: 12rem;

  max-width: 950px !important;
  overflow-y: auto;
  height: 95vh;
  border-radius: 15px;

  padding: 0 1rem;
  width: 100%;
  margin: 0 auto;

  background-color: var(--dark-blue);

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.review::-webkit-scrollbar {
  display: none; /* WebKit browsers */
}

.question-width {
  max-width: 950px;
  width: 90%;
  margin: auto;
  margin-top: 2rem;
  border-radius: 32px;
}

.question-header {
  color: #ffffff;
  max-width: 320px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 40px 40px;
  margin: auto;
  background-color: var(--red-blend);
  padding: 0.5rem 2rem;
}

.review_header-con .review-header-content {
  text-align: center;
  font-weight: bold;
  font-size: 1.9rem;
  padding-top: 2rem;
  padding-bottom: 0.5rem;
}

.review__header {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  gap: 2rem;
}

.drop-down {
  max-width: 250px;
  width: 100%;
  align-self: flex-end;
  position: absolute;
  top: 5rem;
  background-color: var(--secondary-color);
  border-radius: 10px;
}

.review__close {
  align-self: flex-end;
}

.review__close svg {
  color: var(--red-blend);
  font-size: 1.5rem;
  cursor: pointer;
}

.drop-down__value {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  border-radius: 6px;
  height: 45px;
  background-color: var(--trivia-darker-bg);
  color: #ffffff;
}

.drop-down__options {
  padding: 0.9rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.9rem;
  border: 2px solid var(--trivia-darker-bg);
  margin-top: -0.25rem;
}

.drop-down__option,
.drop-down__value {
  padding-left: 1rem;
  padding-right: 0.5rem;
  cursor: pointer;
}

.questions-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.question {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.question__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.question__header-question {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.question-number {
  color: #ffffff;
  font-weight: 600;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.options[data-state-option='correct'] {
  background-color: var(--correct-answer);
}

.options[data-state-option='wrong'] {
  background-color: var(--wrong-answer);
}

.option {
  border: 2px solid #ffffff;
  background-color: transparent;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0 0.5rem;
  border-radius: 12px;
}

.option__left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.correct {
  color: var(--correct-answer);
}

.bg-correct {
  border: 1px solid #ffffff;
  background: var(--correct-bg-20);
}

.bg-wrong {
  border: 1px solid #ffffff;
  background: var(--wrong-bg-12);
}

.missed {
  color: var(--orange);
}

.wrong {
  color: var(--wrong-answer);
}

.selected-Option-inner {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ffffff !important;
}

/* Inner Radio */

.inner-radio {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--light-gray);
}

.correct-radio-inner {
  background-color: var(--correct-answer) !important;
}

.wrong-radio-inner {
  background-color: var(--wrong-answer) !important;
}

/* Outer Radio */
.outer-radio {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ffffff;
}

.wrong-radio-outer {
  border: 2px solid var(--wrong-answer) !important;
}

.correct-radio-outer {
  border: 2px solid var(--correct-answer) !important;
}

.option p {
  font-weight: 600;
}

.question-explain__header {
  color: var(--red-blend);
}

.question-explain {
  min-height: 200px;
  border: 2px solid #ffffff;
  border-radius: 12px;
  background-color: var(--trivia-darker-bg);
  padding: 0.3rem 0.6rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  font-weight: 600;
  padding: 0.9rem 0.4rem;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.question-explain::-webkit-scrollbar {
  display: none; /* WebKit browsers */
}

/* Media Query */

@media screen and (max-width: 450px) {
  .review-speed {
    display: none;
  }
}
