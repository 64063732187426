.dashboard-home-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.dashboard-home-card {
  max-width: 253px;
  width: 100%;

  border-radius: 9px;
  display: flex;
  flex-direction: column;

  gap: 1.6rem;
  padding: 1.5rem 1rem 0.5rem 1rem;
  border: 0.69px solid var(--secondary-color);
  cursor: pointer;
}

.dashboard-home-card__img {
  width: 100%;
  height: 200px;
}

.dashboard-home-card__img img {
  width: 100%;
  height: inherit;
  object-fit: cover;
  object-position: 50%;
  image-rendering: crisp-edges;
}

.dashboard-home-card__content {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.dashboard-home-card__content h3 {
  font-size: 1.3rem;
}

.dashboard-home-card__content p {
  color: var(--text-mid);
}

.modal-multiplayer {
  background-color: var(--dark-blue);
  max-width: 650px;
  border-radius: 15px;
  width: 100%;
  padding: 3.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
}

.modal-multiplayer p {
  font-size: 1.3rem;
  cursor: pointer;
}

.modal-multiplayer .modal-cancel {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

@media screen and (max-width: 550px) {
  .dashboard-home-cards {
    justify-content: center;
  }
}

.list {
  display: flex;
  list-style: none;
  gap: 20px;
  overflow-x: scroll;
  scrollbar-width: none; /* Hide scrollbar in Firefox */
  -ms-overflow-style: none; /* Hide scrollbar in Internet Explorer */
}

.ist::-webkit-scrollbar {
  height: 3px;
  display: none;
}

.feature-item {
  cursor: pointer;
  width: 486px;
  min-width: 468px;
  height: 286px;
  padding: 20px 15px;
  border-radius: 17.88px;
  position: relative;
}
.feature-item h5 {
  font-weight: 600;
  font-size: 31.28px;
  line-height: 42.6px;
  width: 60%;
}
.feature-item p {
  font-size: 22.34px;
  line-height: 30.43px;
  width: 55%;
  margin-top: 10px;
}

.feature-img-container {
  position: absolute;
  right: 0;
  top: 10%;
  bottom: 10;
  background: "red";
}

.feature-item img {
  width: 245px;
  height: 245px;
}

.feature-item-active {
  border: 1px solid #349aff;
}

.text-container {
  display: flex;
  flex-direction: column;
  gap: 10;
}

.gradient-container {
  position: absolute;
  top: 0;
  right: 0%;
}

.gradient-container img {
  width: 329px;
}

.gradient-btn-wrapper {
  display: inline-block;
  background: linear-gradient(to right, #e361e1, #6691e9);
  padding: 2px;
  border-radius: 10px;
  width: 158px;
  z-index: 100;
}

.gradient-btn {
  display: inline-block;
  background: linear-gradient(to right, #e3af61cc, #8c43c7cc);
  border: none;
  border-radius: 7px;
  padding: 10px 20px;
  font-size: 18px;
  color: white;
  cursor: pointer;
  text-align: center;
  height: 50px;
  width: "100%";
}
.white-btn {
  display: inline-block;
  background: white;
  border: none;
  border-radius: 7px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  height: 50px;
}

.filter-item-active {
  position: relative;
  background: linear-gradient(to right, #e3af6147, #8c43c747);
  color: white;
  cursor: pointer;
}
.filter-item {
  display: "block";
  position: relative;
  color: white;
  background-color: "#7B7B7B";
  align-self: center;
  padding: 1rem 2rem;
  cursor: pointer;
  border: 1px solid #7b7b7b;
  border-radius: 10px;
}

/* Media Queries for Medium Screens */
@media (max-width: 1024px) {
  .feature-item {
    width: 350px;
    min-width: 330px;
    height: 240px;
    padding: 15px 10px;
    overflow: hidden;
  }
  .feature-item h5 {
    font-size: 24px;
    line-height: 34px;
  }
  .feature-item p {
    font-size: 18px;
    line-height: 26px;
  }
  .feature-gradient {
    width: 400px;
  }

  .feature-img-container {
    position: absolute;
    right: 0;
    background: "red";
    top: 30%;
  }
  .feature-item img {
    width: 180px;
    height: 180px;
  }

  .gradient-container img {
    width: 259px;
  }

  .gradient-btn-wrapper {
    width: 130px;
  }

  .gradient-btn {
    border-radius: 7px;
    padding: 5px 10px;
    height: 50px;
  }
  .white-btn {
    border-radius: 7px;
    padding: 5px 10px;
    height: 50px;
  }
}

/* Media Queries for Small Screens (Mobile) */
@media (max-width: 768px) {
  .feature-item {
    width: 280px;
    min-width: 260px;
    height: 200px;
    padding: 10px;
  }
  .feature-item h5 {
    font-size: 20px;
    line-height: 28px;
    width: 70%;
  }
  .feature-item p {
    font-size: 16px;
    line-height: 20px;
    width: 60%;
  }
  .feature-item img {
    width: 150px;
    height: 150px;
  }

  .gradient-container img {
    width: 209px;
  }

  .gradient-btn-wrapper {
    width: 100px;
  }

  .gradient-btn {
    border-radius: 7px;
    padding: 0px 5px;
    font-size: 14px;
    height: 40px;
  }
  .white-btn {
    border-radius: 7px;
    padding: 5px 5px;
    font-size: 14px;
    height: 40px;
  }
}

@media (max-width: 426px) {
  .feature-item {
    height: 180px;
  }
  .feature-item h5 {
    font-size: 18px;
  }
  .feature-item p {
    font-size: 14px;
  }
  .feature-item img {
    width: 140px;
    height: 140px;
  }
}
