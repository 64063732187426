.pricing {
  width: 100%;
}

.pricing__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  padding: 3rem 0 4rem 0;
}

.pricing__header h1 {
  text-align: center;
  max-width: 900px;
  width: 100%;
}

.pricing__header p {
  max-width: 940px;
  text-align: center;
}

.pricing__con {
  background-color: #ffffff;
  color: var(--dark-text);
  padding: 2rem 0 3rem 0;
}

.pricing__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

.pricing__main-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.pricing__main-header p {
  max-width: 750px;
}

.pricing__indicator {
  border: 1px solid #eaebf0;
  background: #f7f7f8;
  max-width: 384px;
  width: 100%;
  height: 46px;
  padding: 4px;

  border-radius: 5px;

  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.pricing__indicator span {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 18px 9px 18px;
  cursor: pointer;
  color: var(--light-text);
}

.pricing__indicator-active {
  box-shadow: 0px 1px 2px 0px #1018280a;
  background: #ffffff;
  height: 38px;
  gap: 6px;
  border-radius: 5px;

  color: var(--dark-text);
}

.pricing__table {
  width: 100%;
}

.pricing__table .caption {
  text-align: end;

  width: 100%;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.pricing__table-feature,
.pricing__table-price,
.pricing__table-header {
  display: flex;
  width: 100%;
}

.pricing__table-header div,
.pricing__table-price div,
.pricing__table-feature div {
  flex: 1;
  font-weight: bold;
  font-size: 1.3rem;
  align-self: flex-start;
}

.pricing__table-price div {
  font-size: 1.7rem;
}

.pricing__table-header div:first-child {
  font-size: 1rem;
}

.pricing__table-feature div {
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--darker-text) !important;
}

.pricing__table-features {
  width: 100%;
  padding-bottom: 0;
  padding-top: 0;
}

.pricing__table-feature__item,
.pricing__table-price__item,
.pricing__table-header__item {
  padding-bottom: 0.7rem;
  padding-top: 1rem;
  height: 90px;
  border-bottom: 1px solid #2e3545;
  width: 100%;
}

.pricing__table-header__item {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.pricing__table-header__item-pro {
  flex-direction: column;
  align-items: flex-start;
  gap: 0.7rem;
}

.pricing__table-header__item .value {
  font-size: 1.5rem;
}

.pro {
  padding-left: 0.4rem;
  border-bottom: 1px solid #ffffff;
}

.start_header-bg {
  background: #e3af61;
}

.pro_header-bg {
  background: #349aff;
}

.premium_header-bg {
  background: #9c62db;
}

.feature__item-text {
  font-size: 1.2rem;
}

.feature__item-text-span {
  font-size: 0.85rem;
  margin-left: 0.9rem;
}

@media screen and (max-width: 550px) {
  .pricing__table-header div p,
  .pricing__table-price__item {
    font-size: 1rem !important;
  }

  .pricing__table-feature span {
    font-size: 0.85rem !important;
  }
}
