.stripe-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.stripe-form h2 {
  font-size: 1.6rem;
  text-align: center;
}

.form-stripe__item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: 200px;
  max-width: 100%;
}

.form-stripe__item label {
  font-weight: bold;
}

.form-stripe__item .StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.CardNumberField-input-wrapper {
  border: 2px solid yellow !important;
  height: 45px;
}
.form-stripe__item .ElementsApp .InputElement {
  color: var(--darker-text) !important;
}

.form-stripe__item-row {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-stripe__row {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.stripe-img {
  width: 130px;
  border-radius: 4px;
}

.form-stripe__cards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.stripe-img-card-con {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stripe-img-card-con img {
  width: 150px;
}

.form-stripe__btn {
  align-self: center;
  margin-top: 1rem;
  height: 45px;

  background-color: var(--dark-blue);
  color: #ffffff;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.3);
}

.powered-by-stripe {
  max-width: 248px;
  display: flex;
  justify-content: center;
  margin: 1rem auto;
}

.powered-by-stripe img {
  width: 100%;
}

.trail-text {
  text-align: center;
}
