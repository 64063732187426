.medical-trivia__main {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.medical-trivia-back-nav {
  cursor: pointer;
}

.medical-trivia__header {
  display: flex;
  gap: 2rem;
  align-items: center;
}
.medical-trivia__cards {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.medical-trivia__card {
  max-width: 253px;
  width: 100%;

  border-radius: 9px;
  display: flex;
  flex-direction: column;

  gap: 1.6rem;
  padding: 1.5rem 1rem 0.5rem 1rem;
  border: 0.69px solid var(--secondary-color);
  cursor: pointer;

  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 350px !important;
}

.medical-trivia__card-img {
  width: 100%;
  height: 200px;
}

.medical-trivia__card-img img {
  width: 100%;
  height: inherit;
  object-fit: cover;
  object-position: 50%;
  image-rendering: crisp-edges;
}

.medical-trivia__card-content {
  text-align: center;
}

.medical-trivia__card-content-rotate {
  font-weight: bolder;
  font-size: 1.95rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-50deg);
  white-space: nowrap;
  position: absolute;
}
