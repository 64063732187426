.countdown-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.countdown-container h4 {
  font-size: 40px;
  font-weight: 700;
}
.countdown-container p {
  font-size: 20px;
  font-weight: 700;
}
.countdown-container small {
  font-size: 18px;
  font-weight: 400;
}
.countdown-container img {
  width: 380px;
  height: 380px;
}

.look-at-case {
  background: linear-gradient(to right, #74747440, #52525240);
  border: 1px solid #349aff;
  width: 171px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.case-content {
  color: white;
  /* background-color: white; */
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.case-content p {
  font-size: 18px;
  font-weight: 400;
  color: white;
}

.text-title {
  font-size: 18px;
  font-weight: 700;
}
.text-content {
  font-size: 18px;
  font-weight: 400;
}

.text-area-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #000b5b;
  padding: 20px 31px;
  position: relative;
}

.text-area-container textarea {
  scrollbar-width: thin;
}

.text-area-container textarea::-webkit-scrollbar {
  width: 6px;
}

.text-area-container textarea::-webkit-scrollbar-track {
  background: transparent;
}

.text-area-container textarea::-webkit-scrollbar-thumb {
  background: #349aff;
  border-radius: 3px;
}

@media (max-width: 768px) {
  .countdown-container h4 {
    font-size: 24px;
    font-weight: 700;
  }

  .countdown-container p {
    font-size: 18px;
    font-weight: 700;
  }
  .countdown-container small {
    font-size: 14px;
    font-weight: 400;
  }

  .countdown-container img {
    width: 280px;
    height: 280px;
  }

  .text-title {
    font-size: 14px;
  }
  .text-content {
    font-size: 14px;
  }
  .text-area-container {
    padding: 10px 20px;
    gap: 10px;
  }
  .case-content p {
    font-size: 14px;
  }
}
