.main-setting-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.setting-main-content {
  display: flex;
  gap: 3rem;
  height: 100%;
}

.setting-right-side {
  flex: 1;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding-left: 2rem;
  padding-right: 1rem;
}

.setting-right-side::-webkit-scrollbar {
  display: none;
}

.main-setting-container .dashboard-setting-sidebar__animated-arrow {
  position: absolute;
  top: -1rem;
  left: 12.5rem;
  z-index: 3;
}

.main-setting-container .dashboard-setting-sidebar__animated-arrow img {
  width: 45px;
  height: 45px;
  cursor: pointer;
}

.show-mobile {
  display: none;
}

@media screen and (max-width: 950px) {
  .show-desktop {
    display: none !important;
  }

  .show-mobile {
    display: flex;
    flex-direction: column;
    gap: 3.5rem;
  }

  .mobile-setting {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  .mobile-setting__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 0.8rem;
  }

  .mobile-setting__header .indicator p {
    font-size: 1.4rem;
    text-transform: capitalize;
    text-decoration: double underline var(--secondary-text);
  }

  .mobile-setting__header svg {
    font-size: 1.5rem;
  }
}
