.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal__child {
  z-index: 3;
  width: 100%;
  display: flex;
  justify-content: center;
}
