.multiplayer-invited {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.multiplayer-invited__header {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.multiplayer-invited__header p {
  font-size: 1.4rem;
}

.invited-code-con {
  box-shadow: 0px 8px 20px 0px #00000040;

  border: 4px solid var(--secondary-color);

  max-width: 472px;
  width: 100%;

  border-radius: 32px;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  padding: 2rem;
}

.invited-code-con form input {
  height: 64px;

  border-radius: 9px;
  border: 0.8px solid #7b7b7b;
  background-color: transparent;
  color: #ffffff;
  padding-left: 0.9rem;
  font-size: 1.5rem;
}

.invited-code-con form input:focus,
.invited-code-con form input:active {
  outline: none;
}

.multiplayer-invited__btn {
  background-color: var(--secondary-color);
  border-radius: 13px;
  color: #ffffff;
  align-self: center;
}
