.single-options {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.single-options__input-item::placeholder {
  color: #ffffff;
}

.single-options .answer-option {
  border: 1.5px solid var(--correct-answer-border);
}

.single-options .normal-option {
  border: 0.8px solid var(--gray-border);
}
