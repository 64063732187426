.pitch {
  background-color: var(--mid-color);
  padding: 4rem 0;
}

.pitch-con {
  display: flex;
  align-items: center;
  gap: 4rem;
  justify-content: space-between;
}

.pitch-left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 540px;
  transform: scale(0);
}

.pitch__heading span {
  font-size: 2rem;
}

.pitch__heading {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
}

.pitch__quiz-cards {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: flex-end;
  visibility: hidden;
}

.pitch__quiz-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  font-size: 1em;

  padding: 1rem 2rem;

  height: 342px;
  background: linear-gradient(#000036, #000036) padding-box,
    linear-gradient(269.95deg, #efeeee -4%, rgba(239, 238, 238, 0.32) 99.94%)
      border-box;
  border-radius: 20px;
  border: 1px solid transparent;
}

.pitch__quiz-card-header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.pitch__quiz-card-btn {
  color: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 4px 9px 0px #0b0e2457 inset;

  background: linear-gradient(90deg, #e3af61 0%, #8c43c7 100%);
}

.footer__con-socials {
  flex-wrap: wrap;
  display: flex;
}

@media screen and (max-width: 950px) {
  .pitch {
    padding: 3rem 0;
  }

  .pitch-con {
    flex-direction: column-reverse;
    gap: 3rem;
  }
}

@media screen and (max-width: 640px) {
  .pitch__quiz-cards {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;
  }
}
