.footer {
  background-color: var(--dark-blue);
}

.footer__con {
  padding: 3rem 0 2rem 0;
  display: flex;
  gap: 3rem;

  flex-direction: column;
}

.footer__con-upper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 3rem;
}

.footer__con-items {
  display: flex;
  flex-direction: column;
  gap: 3rem;

  cursor: pointer;
}

.footer__con-down {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 3rem;
}

.footer__con-down__privacy-address {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer__con-down__privacy-address div {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footer__con-down__privacy-address div svg {
  opacity: 0.65;
}

.footer__con-down-privacy {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footer__con-down-privacy p {
  cursor: pointer;
}

.footer__con-down p {
  font-size: 0.85rem;
  color: var(--secondary-text);
}

.footer__con-lists {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.footer__con-lists li {
  list-style-type: none;
}

.footer__con-socials {
  display: flex;
  gap: 2rem;
}
