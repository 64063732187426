.question-bank__menu {
  cursor: pointer;
}

.question-bank__menu-popup-menu {
  display: flex;
  flex-direction: column;
  gap: 2.2rem;

  background-color: var(--mid-color);
  max-width: 500px;
  width: 100%;

  padding: 2rem 0.4rem 2rem 2.5rem;
  border-radius: 18px;
}

.question-bank__menu-popup-menu li {
  list-style-type: none;
  cursor: pointer;
}

.question-bank__menu-popup-menu li:hover {
  color: var(--border-ring);
}
