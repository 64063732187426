.search-bar {
  width: 100%;
  height: 100%;
  border: 0.8px solid #7b7b7b;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-right: 0.8rem;
}

.search-bar input {
  height: 100%;
  width: 100%;
  padding-left: 0.8rem;
  background-color: transparent;
  border: none;
  color: #ffffff;
}

.search-bar input:active,
.search-bar input:focus {
  outline: none;
}
