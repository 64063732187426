.ai-create {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
}

.form-main-ai {
  display: flex;
  gap: 6rem;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.ai-create__headder {
  color: var(--light-orange);
  border: 1px solid var(--light-orange);
  padding: 1rem;
  align-self: flex-end;
  border-radius: 4px;
  cursor: pointer;
}

.form-main-ai__prompt-url,
.form-main-ai__prompt-upload-pdf,
.form-main-ai__prompt-view-pdf {
  opacity: 0;

  width: 100%;
  border-radius: 8px;
  border: 0.6px solid var(--secondary-color);
}

.ease-in-pdf-url-animation {
  animation: ease-in-animation ease-in 0.7s forwards;
}

.ease-out-pdf-url-animation {
  animation: ease-out-animation ease-out 0.55s;
}

@keyframes ease-in-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes ease-out-animation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.form-main-ai__prompt-view-pdf {
  opacity: 1 !important;

  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
  justify-content: space-between;
  flex-wrap: wrap;
}

.form-main-ai__prompt-view-pdf .edit-pdf {
  cursor: pointer;
}

.form-main-ai__prompt-holder {
  height: 90px;
}

.form-main-ai__prompt-upload-pdf {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  cursor: pointer;
}

.form-main-ai__prompt {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  max-width: 446px;
  width: 100%;
}

.form-main-ai__prompt-textarea textarea,
.form-main-ai__prompt-url textarea {
  background-color: transparent;
  width: 100%;
  padding: 16px;
  height: 85%;
  border: none;
  color: #ffffff;
}

.form-main-ai__prompt-textarea textarea {
  display: block;
  width: 100%;
  max-height: 230px;
  overflow-y: auto;
  resize: none;
  border-bottom: 0.5px solid #c1c1c7;

  line-height: 1.5rem;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.form-main-ai__prompt-textarea textarea::-webkit-scrollbar {
  width: 0px; /* For Chrome, Safari, and Opera */
}

.form-main-ai__prompt-textarea {
  width: 100%;
  border-radius: 8px;
  height: 100%;
  border: 0.6px solid var(--secondary-color);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.prompt-send {
  align-self: flex-end;
  max-width: 35px;
  width: 100%;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.form-main-ai__prompt-feature {
  display: flex;
  align-items: center;
  gap: 3rem;
  align-self: center;
  flex-wrap: wrap;
}

.form-main-ai__prompt-feature-item {
  border: 1px solid var(--secondary-color);
  min-width: 85px;
  height: 34px;
  padding: 8px 6px;

  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
  cursor: pointer;
}

.prompt-done-btn {
  min-width: 100%;
  border: none;
  color: #ffffff;
  background-color: var(--secondary-color);
}

.form-main-ai__prompt-feature-item p {
  color: var(--secondary-color);
}

.form-main-ai__details {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  flex: 1;
}

.form-main-ai__detail {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;

  flex-wrap: wrap;
}

.form-main-ai__detail-content {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.form-main-ai__detail-content p {
  font-size: 1.2rem;
  color: #ffffff;
}

.form-main-ai__detail-content span {
  font-size: 0.9rem;
  color: var(--fade-text-2);
}

.form-main-ai__detail-input-range input {
  width: 86px;
  height: 46px;
  padding: 14px 8px 14px 8px;

  border-radius: 4px;
  background-color: transparent;
  color: #ffffff;
  border: 0.5px solid #c1c1c7;
}

.form-main-ai__detail-input-range input:focus,
.form-main-ai__detail-input-range input:hover,
.form-main-ai__detail-input-select select:focus,
.form-main-ai__detail-input-select select:hover {
  outline: none;
}

.form-main-ai__detail-input-select {
  height: 46px;
  border: 0.5px solid #c1c1c7;
  border-radius: 4px;
}

.form-main-ai__detail-input-select select {
  height: 100%;
  background-color: transparent;
  border: none;
  color: #ffffff;
  padding: 10px;
}

.form-main-ai__detail-input-select select option {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: transparent;
  color: var(--fade-text-2);
}
