.login {
  min-height: 100vh;
  background-color: var(--mid-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}

.auth-form {
  margin-top: 2rem;
  max-width: 740px;
  width: 95%;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
}

.auth-input-con {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.auth-input-con p {
  font-size: 1rem;
}

.input-text,
.password {
  height: 45px;
  border: 0.5px solid #349aff;
  box-shadow: 0px 1px 2px 0px #1018280a;
  background-color: var(--input-bg);
  border-radius: 6px;
  padding-left: 10px;
  color: #ffffff;
}

.auth-input-con input[type='text']:focus,
.input-password:focus {
  outline: none;
  background-color: transparent;
}

.input-password {
  width: 100%;
  background-color: transparent;
  border: none;
  height: 100%;
  padding-left: 10px;
  color: #ffffff;
}

.input-password:active {
  outline: none;
}

.password {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding-right: 10px;
  padding-left: 0;
}

.password svg {
  cursor: pointer;
}

.agree-con {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.check-con {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.custom-checkBox {
  border: 1px solid var(--light-blue);
  border-radius: 5px;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.custom-checkBox svg {
  color: #ffffff;
}

.forget-password {
  color: var(--light-orange);
  cursor: pointer;
}

.auth-suggestion {
  display: flex;
  gap: 2rem;
  align-items: center;
}
.suggest {
  cursor: pointer;
  color: var(--primary-btn-color);
}

.term-agreement {
  display: flex;
  gap: 0.5rem;
}

.term-link {
  cursor: pointer;
  color: var(--primary-btn-color);
}
