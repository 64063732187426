.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
}

.navbar__logo-links {
  display: flex;
  gap: 5rem;
}

.nav__btn {
  background-color: var(--primary-btn-color);
  color: #ffffff;
}

.nav__dashboard {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.nav__dashboard-logout {
  border: 2px solid var(--primary-btn-color);
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  border-radius: 8px;
  cursor: pointer;
}

.navbar__logo {
  position: relative;
  cursor: pointer;
  z-index: 2;
}

.navbar__links {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.bold__links {
  font-weight: bold;
}

.navbar__link {
  list-style: none;
}

.navbar__lang-auths {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  z-index: 3;
}

.navbar__lang-con {
  position: relative;
}

.navbar__lang {
  display: flex;

  align-items: flex-end;
  gap: 1rem;
}

.nav__lang-selections {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  z-index: 6;
  border: 2px solid red;
  right: 0;
  left: 0;
  padding: 0.6rem;
}

.navbar__lang img {
  margin-bottom: -5px;
}

.navbar__auths {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.navbar__auths p {
  font-weight: 600;
}

.auth-signup {
  color: var(--secondary-color);
}

.auth-login {
  box-shadow: 0px 1px 2px 0px #1018280a;
  background: #000b5b;
  cursor: pointer;
  padding: 12px;
  border-radius: 6px;
}

.navbar__link,
.navbar__auth,
.navbar__lang span {
  font-size: 1.3rem;
  cursor: pointer;
}

.mobile__toggle-menu {
  z-index: 5;
}

.navbar__mobile,
.mobile__toggle-menu,
.overlay {
  display: none;
}

.mobile__toggle-item-close {
  width: 35px;
  height: 35px;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.mobile__toggle-item-open {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

/* Media Query  */
@media screen and (max-width: 950px) {
  .navbar__links,
  .navbar__lang-auths {
    display: none;
  }

  .mobile__toggle-menu {
    display: block;
  }

  .navbar__mobile,
  .navbar__mobile-items,
  .nav__dashboard {
    display: flex;
    flex-direction: column;
  }

  .navbar__mobile {
    align-items: center;
    gap: 2rem;
  }

  .nav__btn {
    max-width: 320px !important;
    width: 100%;
  }

  .mobile-dashboard-logout {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  /* .navbar__mobile {
    position: absolute;
    flex-direction: column;
    z-index: 2;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 400px;
    width: 100%;
  } */

  .navbar__lang-auths {
    flex-direction: column;
    align-items: center;
  }
  .navbar__auths {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .overlay {
    display: flex;
  }
}
