.timer {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.timer__details {
  display: flex;
  gap: 0.6rem;
  align-items: flex-end;
  justify-content: flex-end;
}

.timer__count {
  font-size: 2rem;
}

.timer__delimeter {
  margin-bottom: -0.3rem;
}

.timer__range {
  width: 200px;
  margin-bottom: -1rem;
}

/* Slider style */
/* Style the input range */

.timer__range input {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  height: 15px;
  margin: 10px 0;
  pointer-events: none;
  border-radius: 22px;

  transition: all 450ms ease-in;
}

/* Customize the slider thumb (handle) */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 15px;
  border-radius: 50%;
  cursor: pointer;
}

/* Customize the slider track (background) */
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 15px;
  border-radius: 22px;
}

@media screen and (max-width: 450px) {
  .timer {
    flex-direction: column;
  }
}
