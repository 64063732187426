.about-bg {
  background-color: var(--dark-blue);
  padding: 3rem 0;
}

.about {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-top: 5rem;
}

.about__header {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.about__feature {
  position: relative;
  padding: 1.8rem 4rem;
  font-weight: bold;
}

.about__feature::before {
  border: 1px solid;

  background-color: linear-gradient(100.98deg, #e361e1 14.41%, #6691e9 98.86%);
}

.about h2 {
  text-align: center;
}

.about__header-text {
  text-align: center;
  max-width: 925px;
  width: 100%;
  color: var(--secondary-text);
  margin: 0 auto;
  font-size: 1rem;
  transform: scale(0);
}

.about_points {
  display: grid;
  position: relative;
  z-index: 3 !important;

  grid-template-columns: 1fr 1fr;

  gap: 3rem;
}

.about__box {
  border: 1px solid transparent;

  box-shadow: 0px 4px 9px 0px #1d4f8180;

  padding: 20px 16px 82px 16px;
  gap: 0px;
  border-radius: 16px;
  border: 1px 0px 0px 0px;

  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  visibility: hidden;
}

.about__box:hover {
  position: relative;
  background: linear-gradient(#000036, #000036) padding-box,
    linear-gradient(269.95deg, #e361e1 100%, #6691e9 99.94%) border-box;
  border: 1px solid transparent;
  box-shadow: 0px 4px 9px 0px #1d4f8180;
}

.about__box p {
  color: #ffffff;
}

.about__header-text-sub {
  color: var(--secondary-text);
}

.about_number {
  font-weight: bold;
  font-size: 1.3rem;
}

.feature__case-recall {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 4rem;
}

.feature__case-recall > div:nth-child(even) {
  flex-direction: row-reverse;
}

.feature__case-recall-item {
  display: flex;
  gap: 4rem;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  z-index: 3;
}

.feature__case-recall-item__btn-normal {
  background: linear-gradient(100.98deg, #6166e3 14.41%, #6691e9 98.86%);
  color: #ffffff;
}

.feature__case-recall-item__btn-colored {
  background: linear-gradient(100.98deg, #e361e1 14.41%, #6691e9 98.86%);
  color: #ffffff;
}

.feature__case-recall-item__left {
  max-width: 540px;
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 2rem;
  transform: scale(0);
}

.feature__case-recall-item__left span {
  font-size: 0.95rem;
  color: var(--rear-blue);
}

.feature__case-recall-item__left p {
  text-align: left;
}

.about__feature-img-con {
  visibility: hidden;
}

.about__feature-img-con img {
  max-width: 480px;
  width: 100%;
  object-fit: cover;
  image-rendering: crisp-edges;
}

.medscroll-slides-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.medscroll-slides-container div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 70%;
}

.medscroll-slides-container div p {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
}

.medscroll-slides-container div h4 {
  font-size: 32px;
  font-weight: 700;
}

@media screen and (max-width: 950px) {
  .about_points {
    grid-template-columns: 1fr;
  }

  .medscroll-slides-container div {
    width: 100%;
  }

  .medscroll-slides-container div p {
    font-size: 16px;
  }

  .medscroll-slides-container div h4 {
    font-size: 24px;
  }
}
