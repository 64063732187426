.quiz {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 900px;
  width: 100%;
}

.quiz__timeCount {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.quiz-width {
  width: 100%;
  word-wrap: break-word;
}

.quiz__question-count {
  border-radius: 5.4px 5.4px 0px 0px;
  height: 35px;
  max-width: 109px;
  width: 100%;
  padding-left: 0.8rem;
  display: flex;
  align-items: center;
}

.quiz__question {
  min-height: 95px;
  border-radius: 2.7px;
}

.quiz__question,
.quiz__option,
.quiz__question-count {
  padding: 1.3rem 0.8rem;
  border: 2px solid var(--secondary-color);
}

.quiz__options {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.2rem;
}

.quiz__option {
  min-height: 57px;
  border-radius: 5.4px;
  cursor: pointer;
}

/* Option category
quiz__userAnswer-answerIndication
*/

.quiz__wrong-wrong,
.quiz__correct-correct,
.quiz__correct-answer {
  font-weight: bolder;
}

.quiz__correct-correct {
  background-color: var(--correct-answer);
}

.quiz__wrong-wrong {
  background-color: var(--wrong-answer);
}

.quiz__correct-answer {
  border: 2px solid var(--correct-answer-border);
}

@media screen and (max-width: 450px) {
  .quiz__options {
    grid-template-columns: 1fr;
  }
}
