.dashboard-setting-sidebar {
  overflow-y: auto;
  /* overflow-x: hidden; */
  box-shadow: 12px 11px 21px 0px #349aff17;
  border-right: 1px solid #349aff;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: center;
  padding-right: 0.4rem;
  position: relative;
}

.dashboard-setting-sidebar__links {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.dashboard-setting-sidebar__link {
  display: flex;
  gap: 1.2rem;
  align-items: center;
  cursor: pointer;

  height: 64px;
  padding: 16px;
  gap: 16px;
  border-radius: 8px;
}

.active {
  background-color: var(--secondary-color);
}
