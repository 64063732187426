.cardPrompt {
  max-width: 533px;
  height: 450px;
  width: 85%;
  position: relative;
  background-color: var(--secondary-color);
  background-image: url('../../assets/images/star-pattern.png');
  padding: 2.3rem 1rem;
  border-radius: 15px;

  overflow-y: auto;
  scrollbar-width: none;
}

.cardPrompt__header {
  position: relative;
  margin-top: 2rem;
}

.cardPrompt__header {
  text-align: center;
}

.cardPrompt__close {
  position: absolute;
  top: 1.5rem;
  right: 2rem;
  cursor: pointer;
}

.cardPrompt__close svg {
  font-size: 1.7rem;
}

.cardPrompt__profile-score {
  color: var(--score-color);
  font-size: 2rem;
}

.cardPrompt__profile-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.3rem;
}

.cardPrompt__profile {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardPrompt__profile-detail__name {
  margin-top: -2.5rem;
  font-weight: bold;
}

.cardPrompt__buttons {
  margin-top: 2rem;
}

.cardPrompt__buttons > div {
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  gap: 2rem;
}
