.multiplayer-code {
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  gap: 6rem;
}

.multiplayer-code__header svg {
  cursor: pointer;
}

.multiplayer-code__header {
  display: flex;
  gap: 3rem;
  align-items: center;
}

.main-multiplayer-code {
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  flex-wrap: wrap;
}

.main-multiplayer-code__left-con {
  display: flex;
  gap: 3rem;
  flex-direction: column;
}

.main-multiplayer-code__left {
  display: flex;
  gap: 6rem;
  align-items: flex-end;
  flex-wrap: wrap;
}

.main-multiplayer-code__left-copy-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.5rem;
}

.main-multiplayer-code__left-code {
  display: flex;
  gap: 2rem;
  align-items: flex-end;
}

.main-multiplayer-code__left-code .game-creator {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.3rem;
}

.main-multiplayer-code__left-code .game-creator > div {
  width: 180px;
}

.main-multiplayer-code__left-code .game-creator > div img {
  width: 100%;
  height: 180px;
  border-radius: 9px;
  object-fit: cover;
  image-rendering: crisp-edges;
}

.input-multiplyer {
  border: 1px solid var(--secondary-color);

  width: 284px;
  height: 64px;

  border-radius: 4px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.input-multiplyer label {
  max-width: 50px;
  text-align: center;
  background-color: var(--dark-blue);
  position: absolute;
  top: -1rem;
  left: 0;
  font-weight: bold;
}

.input-multiplyer__input-box {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.input-multiplyer__input-box input {
  height: 100%;
  width: 100%;
  padding-left: 0.88rem;
  background-color: transparent;
  border: none;
  outline: none;
  color: #ffffff;
}

.input-multiplyer__input-box .main-code-input {
  font-size: 2rem;
  font-weight: bold;
}

.input-multiplyer__input-box svg {
  min-width: 48px;
}

.code-btn {
  background-color: var(--secondary-color);
  color: #ffffff;
}

.users-joined {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-wrap: wrap;
}

.user-joined-con {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 2rem;
}

.user-joined-con-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.user-joined-con-item__img-con img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  image-rendering: crisp-edges;
  border-radius: 9px;
}

.user-joined-con-item p {
  text-align: center;
  gap: 1.1rem;
}

.qr-code-view {
  height: 100%;
}
