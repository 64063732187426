.auth-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
  width: 100%;
}

.auth-header h2 {
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
}

.google-auth {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  max-width: 280px;
  width: 100%;
  padding: 1rem 2rem;
  cursor: pointer;
}

.auth-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.auth-indicator::before,
.auth-indicator::after {
  content: '';
  display: inline-flex;
  align-items: center;
  align-self: center;
  width: 100%;

  justify-content: center;
  justify-self: center;
  max-width: 90px;
  height: 2px;
  background-color: #ffffff;
  position: relative;
}

.auth-indicator::before {
  margin-right: 10px;
}
.auth-indicator::after {
  margin-left: 10px;
}

@media screen and (max-width: 560px) {
  .auth-indicator::before,
  .auth-indicator::after {
    max-width: 50px;
  }
}
