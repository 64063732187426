.toggle-slider__outer {
  position: relative;
  display: inline-block;
  width: 62px;
  height: 34px;
  padding: 2px 2px 2px 3px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toggle-slider__inner {
  box-shadow: 0px 1px 3px 0px #1018281a;
  width: 25px;
  height: 25px;
  border-radius: 15px;
  background-color: #ffffff;
  position: absolute;
}

.toggle-true {
  right: 7px;
  transition: 0.4s;
}

.toggle-false {
  left: 7px;
  transition: 0.4s;
}

.toggle-slider__outer-toggle-left {
  background-color: #dae0e6;
  transition: 0.4s;
}

.toggle-slider__outer-toggle-right {
  background-color: var(--secondary-color);
  transition: 0.4s;
}
