.ai-spinner {
  background-color: var(--dark-blue);
  max-width: 650px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
  border-radius: 14px;
  padding: 1.5rem;
}

.ai-spinner__header p {
  font-size: 1.5rem;
}

.ai-spinner__header {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.ai-spinner__content {
  color: var(--secondary-color);
  max-width: 350px;
  width: 100%;
  text-align: center;
}
