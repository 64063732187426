.trivia {
  padding-top: 3rem;
  display: flex;
  gap: 3rem;
  position: relative;
  flex-wrap: wrap;
}

.trivia-back-arrow {
  cursor: pointer;
}

.trivia-points {
  display: flex;
  gap: 2rem;
}

.trivia-quiz {
  width: 100%;
  max-width: 950px;
  margin: 0 auto;
}

.trivia-close {
  position: absolute;
  top: 3rem;
  right: 0;
  z-index: 2;
}
