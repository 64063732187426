.question-bank-quiz-select {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 3rem 0;
}

.question-bank-quiz-select .question-bank__headers {
  flex-wrap: wrap;
}

.question-bank__card-checker {
  width: 28px;
  height: 28px;
  border-radius: 9px;
  border: 2px solid var(--orange);
  display: flex;
  align-items: center;
  justify-content: center;
}

.quiz-select-btn {
  color: #ffffff;
  background-color: var(--secondary-color);
}
