.terms__con {
  background-color: #ffffff;
  color: var(--dark-text);
  padding: 2rem 0 3rem 0;
}

.terms__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

.terms__main-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.terms__main-body {
  line-height: 1.8rem;
  display: flex;
  flex-direction: column;
  gap: 0.85rem;
}
