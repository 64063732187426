.drop-zone {
  padding: '20px';
  text-align: 'center';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drop-zone__context {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.question-add-thumbs-view {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: 'flex';
  align-items: center;
  border-radius: '2px';
  margin-bottom: '8px';
  margin-right: '8px';
  width: '100%';
  padding: '4px';
  box-sizing: 'border-box';
}

.question-add-thumbs-view__item {
  height: 100%;
  /* position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
}

.question-add-thumbs-view__item img {
  width: 100%;
  /* position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
  object-fit: cover;
  image-rendering: pixelated;
}

.thumb-parent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: 'flex';
  align-items: center;
  justify-content: center;
  flex-wrap: 'wrap';
  margin-top: '16px';
  overflow: hidden;
  border-radius: 18px;
}
