.confirm-otp__otp {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 1;
  flex-wrap: wrap;
}

.confirm-otps {
  display: flex;
  gap: 0.1rem;
}

.confirm-otps input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.confirm-otps input {
  max-width: 50px;
  align-items: center;
  height: 50px;
  padding: 0.8rem;
}

.confirm-otp__otp h3 {
  font-size: 1.8rem;
}

.confirm-otp__btn {
  background-color: var(--primary-btn-color);
  color: #ffffff;
  border: none;
}
