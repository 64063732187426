/* General styling for all toolbar buttons */
.ql-toolbar button {
  background-color: #ffffff !important;
  border: 1px solid #ccc !important;
  color: #333 !important;
  border-radius: 4px;
  padding: 5px;
  margin: 2px;
}

/* Change hover effect for toolbar buttons */
.ql-toolbar button:hover {
  background-color: #f0f0f0 !important;
  border-color: #999 !important;
}

/* Customize active state for buttons */
.ql-toolbar button.ql-active {
  background-color: #007bff !important;
  color: white !important;
}

/* Customize dropdown menus in toolbar */
.ql-toolbar .ql-picker {
  background-color: #ffffff !important;
  border: 1px solid #ccc !important;
  color: #333 !important;
}

.submit-btn {
  background: linear-gradient(to right, #74747440, #52525240);
  border: 1px solid #349aff;
  width: 171px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  align-self: flex-end;
  align-items: center;
  cursor: pointer;
}
