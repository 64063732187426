.new-passwword-con {
  min-height: 100vh;
  background-color: var(--mid-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}

.new-passwword-con .confirm-otp {
  background-color: transparent !important;
  align-items: center !important;
}

.reset-otp h3 {
  font-size: 1.1rem;
}

.new-password-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;
}

.new-password-main__header {
  display: flex;
  flex-direction: column;
  gap: 1.1rem;
  padding: 1rem 1.5rem;
  max-width: 740px;
  width: 95%;
}

.new-password-main__header h3 {
  font-size: 1.5rem;
}

.new-passwword-con form {
  gap: 1rem !important;
}

.new-passwword-con .auth-btn {
  margin-top: 1.3rem;
}
