.home {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.decor-gradient-top-right {
  max-width: 444px;
  width: 100%;

  position: absolute;
  right: 0;
  z-index: 1;
}

.home__preview {
  margin-top: 5rem;
  padding: 3rem 0;
}

.home__preview-content {
  display: flex;
  gap: 4rem;
  flex-wrap: 4rem;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 3rem;
}

.home__preview-content__left {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  max-width: 540px;
  visibility: hidden;
}

.home__preview-img {
  position: relative !important;
  visibility: hidden;
}

.home__preview-img img,
.home__preview-img__content-mobile,
.home__preview-img__content {
  cursor: pointer;
}

.home__preview-img img {
  max-width: 550px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.home__preview-img img {
  width: 100%;
}

.home__preview-img__content {
  position: absolute;
  top: 1.1rem;
  bottom: 9.5rem;
  left: 50%;
  transform: translate(-50%, 0%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 0.7rem 3rem 0.7rem;
  background-color: var(--dark-blue);
  max-width: 240px;
  width: 100%;
  border-radius: 1.4rem;
  overflow-y: auto;
  scrollbar-width: none;
}

.home__preview-img__content-mobile {
  position: absolute;
  top: 0;
  bottom: 9rem;
  left: 50%;
  transform: translate(-50%, 20%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 0.4rem 3rem 0.4rem;
  border-radius: 1.4rem;
  overflow-y: auto;
  scrollbar-width: none;
}

.home__preview-img__content-mobile p {
  text-align: center;
}

.track-mouse {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  filter: blur(100px);
}

.hide-mobile-mouse-track-pink-bg {
  background: linear-gradient(
    100.98deg,
    rgba(227, 97, 225, 0.9) 100%,
    rgba(102, 145, 233, 0.6) 100%,
    rgba(227, 97, 225, 0.6) 80%,
    rgba(227, 97, 225, 0.5) 50%
  );
  box-shadow: 0 0 50px 120px rgba(227, 97, 225, 0.5),
    0 0 50px 50px rgba(102, 145, 233, 0.3);
}

.hide-mobile-mouse-track-blue-bg {
  background-size: 300% 300%; /* Control the scaling of the gradient */
  animation: rainbowAnimation 1.25s infinite linear;

  box-shadow: 0 0 100px 100px rgba(102, 145, 233, 0.3),
    0 0 50px 120px rgba(227, 97, 225, 0.5);
}

@keyframes rainbowAnimation {
  0% {
    background: linear-gradient(100.98deg, #6166e3 14.41%, #6691e9 98.86%);
    box-shadow: 0 0 50px 120px rgba(227, 97, 225, 0.5),
      0 0 50px 60px rgba(102, 145, 233, 0.3);
  }

  50% {
    background: radial-gradient(
        circle farthest-corner rgba(227, 97, 225, 0.2) 1%,
        #000036 5%
      ),
      linear-gradient(
        100.98deg,
        rgba(227, 97, 225, 0.3) 14.41%,
        rgba(102, 145, 233, 0.6) 85.86%
      );
    box-shadow: 0 0 70px 140px rgba(227, 97, 225, 0.6),
      0 0 120px 120px rgba(102, 145, 233, 0.5);
  }
  100% {
    background: linear-gradient(100.98deg, #6166e3 14.41%, #6691e9 98.86%);
    box-shadow: 0 0 50px 120px rgba(227, 97, 225, 0.5),
      0 0 100px 100px rgba(102, 145, 233, 0.3);
  }
}

.home__preview-img__content-hide,
.hide-mobile-mouse-track {
  display: flex;
}

.home__preview-img__content-show {
  display: none;
}

.home__preview-img__content-text {
  display: inline-block;
  animation: scaleInOut 3s ease-in-out infinite;
  transform-origin: center;
}

@keyframes scaleInOut {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

@media screen and (max-width: 950px) {
  .home__preview-img__content-hide,
  .track-mouse,
  .hide-stars {
    display: none;
  }

  .home__preview-img__content-show {
    display: flex;
  }
}
