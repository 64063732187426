.multiple-options {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.multiple-options__item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  border: 0.8px solid var(--gray-border);
  padding-right: 1rem;
}

.options__item-text {
  border: none;
  height: 100%;
  width: 100%;
  background-color: transparent;
  color: #ffffff;
}

.options__item-text::placeholder {
  color: #ffffff;
}

.multiple-options__checkbox {
  height: 22px;
  width: 22px;
  border-radius: 8px;
  cursor: pointer;
}
