/* Container */
.bars-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px; /* 2 * 4px */
  transform: scale(0.9);
}

/* Bar Item */
.bar-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Large Screen */
.bar-large-screen {
  display: none;
  position: relative;
  width: 70px; /* 20 * 4px */
  height: 1px;
  padding: 1px 0;
  margin: 4px 0;
}

/* Mobile Screen */
.bar-mobile-screen {
  position: relative;
  width: 24px; /* 6 * 4px */
  height: 0.5px;
}

@media (min-width: 1024px) {
  .bar-large-screen {
    display: block;
  }

  .bar-mobile-screen {
    display: none;
  }
}

/* Bar Content */
.bar-content {
  position: absolute;
  inset: 0;
}

.bar-content[data-current='true'] {
  background: var(--no-answer);
}

.bar-content[data-state=''] {
  background-color: var(--no-answer);
}

.bar-content[data-state='correct'] {
  background-color: var(--secondary-color);
}

.bar-content[data-state='wrong'] {
  background-color: var(--wrong-answer);
}
